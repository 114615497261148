import React from "react";

const FailedPage = () => {
  return (
    <div>
      <h1>Failed!</h1>
      <p>Your payment was not successful.</p>
    </div>
  );
};

export default FailedPage;
