import React from "react";
import "../styles/register.css";
import { register } from "../helpers/auth";

const RegisterPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handleRegister = async (event) => {
    event.preventDefault(); // Prevent page reload
    if (email === "" || password === "") {
      setError("Email and password fields are required");
      return;
    }
    await register(email, password);
    window.location.href = "/profile";
  };

  const handleRegisterBand = async (event) => {
    event.preventDefault(); // Prevent page reload
    window.location.href = "/register/band";
  };

  const handleRegisterVenue = async (event) => {
    event.preventDefault(); // Prevent page reload
    window.location.href = "/register/venue";
  };

  return (
    <div classname="register-page">
      <form className="register-form">
        <div className="register-title">Create an Account</div>
        <input
          placeholder="Email"
          name="email"
          className="register-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          placeholder="Password"
          name="password"
          type="password"
          className="register-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <input
          placeholder="Confirm Password"
          name="confirmPassword"
          type="password"
          className="register-input"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        ></input>
        <button className="register-button" onClick={handleRegister}>
          Register as a Fan!
        </button>
        <div>or</div>
        <button className="register-button" onClick={handleRegisterBand}>
          Register a Band
        </button>
        <button className="register-button" onClick={handleRegisterVenue}>
          Register a Venue
        </button>
        <p>{error}</p>
      </form>
    </div>
  );
};

export default RegisterPage;
