import React, { useEffect, useState } from "react";
import "../styles/results.css";
import { useParams } from "react-router-dom";
import { searchEvents } from "../helpers/firestore";
import HomeCard from "../components/homeCard";
import { ThreeDots } from "react-loader-spinner";

function createHeader(date, location, genre) {
  let header = "Search Results for";
  if (date !== "none") {
    header += ` for ${date}`;
  } else {
    header += " for all dates";
  }
  if (location !== "none") {
    header += ` at ${location}`;
  } else {
    header += " anywhere";
  }
  if (genre !== "none") {
    header += ` for ${genre} events`;
  } else {
    header += " for any kind of event";
  }
}

const SearchResultsPage = (props) => {
  let { date, location, genre } = useParams();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const results = await searchEvents({ date, location, genre });
      setEvents(results);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="results-page">
      <div className="results-list">
        {loading ? (
          <div className="loading">
            <ThreeDots color="#ed46c7" height={100} width={80} />
          </div>
        ) : (
          <>
            {events.length === 0 ? (
              <div className="no-results">No results found</div>
            ) : (
              <>
                <div className="results-header">{createHeader}</div>
                <div className="results-card-wrapper">
                  {events.map((event) => (
                    <HomeCard
                      key={event.id}
                      id={event.id}
                      title={event.performer}
                      description={event.description}
                      location={event.location}
                      image={event.image}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;
