import React, { useEffect, useState } from "react";
import "../styles/bookingDetails.css";
import {
  getBooking,
  getUser,
  updateStatus,
  bookingToEvent,
  getEvent,
} from "../helpers/firestore";
import { deleteEvent } from "../helpers/realtime";
import { calculateFeaturePrice, makePayment } from "../helpers/stripe";

const BookingDetails = (props) => {
  const [booking, setBooking] = useState({});
  const [band, setBand] = useState({});
  const [venue, setVenue] = useState({});
  const [event, setEvent] = useState({});

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const data = await getBooking(props.bookingID);
        setBooking({
          ...data,
          messages: undefined,
          dates: new Date(data.dates.seconds * 1000).toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }
          ),
        });
        if (data.eventID) {
          const eventData = await getEvent(data.eventID);
          eventData.featurePrice = await calculateFeaturePrice(data.eventID);
          setEvent(eventData);
        } else {
          setEvent({});
        }
        const bandData = await getUser(data.band);
        setBand(bandData);
        const venueData = await getUser(data.venue);
        setVenue(venueData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBooking();
  }, [props.bookingID]);

  return (
    <div className="booking-details">
      {props.bookingID ? (
        <div className="booking-details">
          <div className="booking-details-image-wrapper">
            <img
              src={band.image}
              alt={band.name}
              className="booking-details-image"
            />
          </div>
          <div className="booking-details-info">
            <div className="booking-details-title">
              {band.name} @{venue.name}
            </div>
            <div className="booking-details-details">
              <div className="booking-details-date">{booking.dates}</div>
              <div className="booking-details-price">
                ${booking.rate}/hour, ${booking.total} total
              </div>
              <div
                className={`booking-details-status ${
                  booking.status === "pending"
                    ? "booking-details-status-pending"
                    : booking.status === "confirmed"
                    ? "booking-details-status-confirmed"
                    : booking.status === "cancelled"
                    ? "booking-details-status-cancelled"
                    : ""
                }`}
              >
                {booking.status}
              </div>
            </div>

            <div className="booking-details-buttons">
              {props.user.role === "band" && booking.status === "pending" ? (
                <button
                  className="booking-details-button booking-confirm-button"
                  onClick={() => {
                    updateStatus(props.bookingID, "confirmed");
                    setBooking({ ...booking, status: "confirmed" });
                    bookingToEvent(props.bookingID);
                  }}
                >
                  Confirm Booking
                </button>
              ) : null}
              {booking.status !== "cancelled" ? (
                <button
                  className="booking-details-button booking-cancel-button"
                  onClick={() => {
                    updateStatus(props.bookingID, "cancelled");
                    setBooking({ ...booking, status: "cancelled" });
                    deleteEvent(booking.eventID);
                  }}
                >
                  {booking.status === "confirmed"
                    ? "Cancel Booking"
                    : "Decline"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BookingDetails;
