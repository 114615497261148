import React, { useEffect, useState } from "react";
import "../styles/booking.css";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUser, createBooking } from "../helpers/firestore";
import { auth } from "../helpers/firebase";

const stripePromise = loadStripe(
  "pk_test_51O4srXDCHNXaksOuAgPg7RNGPvUAR991E5ZBonqa1D78eqGsg8C2HOG0rB0tQ7sRGroY7bw51JZleTNPomLT6Vxy00VVpH74eb"
);

function BookingPage() {
  let { bandid } = useParams();
  const [band, setBand] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [name, setName] = useState("");
  const [rate, setRate] = useState(0);
  const [details, setDetails] = useState("");
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await getUser(bandid);
        if (data) {
          setBand(data);
        }
        const userData = await getUser(auth.currentUser.uid);
        if (userData) {
          setUser(userData);
        }
        if (!userData.stripeId) {
          window.location.href = "/checkout";
        }
        const response = await fetch(
          "https://jammin-together.ue.r.appspot.com/get-subscription-id",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              session_id: user.stripe_id,
            }),
          }
        );
        console.log(response);
        if (response.stringify() === "true") {
          setSubscribed(true);
        }
      } catch (error) {
        console.error("Error loading band", error);
      }
    };
    loadData();
  }, []);

  const calculateTotal = (start, end) => {
    const diff = Math.abs(end - start);
    return ((diff / 36e5) * rate).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "" || details === "") {
      setError("Please fill out all contact fields");
      return;
    } else if (startDate > endDate) {
      setError("Start date must be before end date");
      return;
    } else if (startDate < new Date()) {
      setError("Start date must be in the future");
      return;
    }
    try {
      await createBooking({
        band: bandid,
        venue: auth.currentUser.uid,
        name: name,
        rate: rate,
        dates: startDate,
        end: endDate,
        total: calculateTotal(startDate, endDate),
        messages: [{ content: details, sender: "venue", time: new Date() }],
        status: "pending",
      });
      window.location.href = "/inbox";
    } catch (error) {
      console.error("Error creating booking", error);
      setError("Error creating booking");
    }
  };

  return (
    <div className="booking-page">
      <div className="booking-wrapper">
        <div className="booking-image-wrapper">
          <img
            src={band ? band.image : ""}
            alt={band ? band.name : ""}
            className="booking-image"
          />
        </div>
        <form className="booking-body" onSubmit={handleSubmit}>
          <div className="booking-header">
            <h1>Book {band ? band.name : ""}</h1>
          </div>
          <label>Contact Info</label>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Contact Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
            />
            <input
              type="text"
              placeholder="Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              name="message"
              autoComplete="off"
            />
          </div>
          <label>Event Info</label>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Hourly Rate ($)"
              value={rate ? "$" + rate : ""}
              onChange={(e) => setRate(e.target.value.replace("$", ""))}
              name="rate"
              autoComplete="off"
            />
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeInput
              dateFormat="MMMM d, yyyy h:mm aa"
              shouldCloseOnSelect={false}
              timeIntervals={60}
              calendarClassName="calendar"
              minDate={new Date()}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="MMMM d, yyyy h:mm aa"
              shouldCloseOnSelect={false}
              showTimeInput
              timeIntervals={60}
              calendarClassName="calendar"
              minDate={startDate}
            />
            <div className="booking-total">
              {calculateTotal(startDate, endDate) > 0 ? (
                <p>Total: ${calculateTotal(startDate, endDate)}</p>
              ) : null}
            </div>
            <div className="booking-terms">
              By clicking "Send Request" you agree to the{" "}
              <a href="/terms">Terms of Service</a>
            </div>
            <button type="submit" className="booking-button">
              Send Request
            </button>
            <div className="booking-error">{error}</div>
          </div>
        </form>
        <div className="close-button">
          <a href="/">X</a>
        </div>
      </div>
    </div>
  );
}

export default BookingPage;
