import React, { useEffect } from "react";
import "../styles/profile.css";
import { logout } from "../helpers/auth";
import { auth } from "../helpers/firebase";
import {
  getUser,
  getSelf,
  openCheckoutPortal,
  bulkAdd,
} from "../helpers/firestore";

const ProfilePage = () => {
  const [user, setUser] = React.useState(null);
  const [uid, setUid] = React.useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        window.location.href = "/login";
      }
      const data = await getSelf();
      setUser(data);
      setUid(user.uid);
    });
  }, []);

  const handleLogout = async (event) => {
    event.preventDefault();
    window.location.href = "/login";
    logout();
  };

  const handleFollowing = async (event) => {
    event.preventDefault();
    window.location.href = "/following";
  };

  const handleSubscription = async (event) => {
    event.preventDefault();
    console.log(uid);
    openCheckoutPortal(uid);
  };

  return (
    <div className="profile-page">
      <div className="profile-content">
        <div className="profile-image">
          <img
            src={
              user && user.image
                ? user.image
                : "https://firebasestorage.googleapis.com/v0/b/jammin-together-4c0c2.appspot.com/o/https___3f533f6ff18ac855ec9b65f4e96478eb.cdn.bubble.io_f1695334819564x524215378535499700_JWRLogo%20(1).png?alt=media&token=1646fc6d-d397-4f87-8e3f-1a3a690da292"
            }
            alt={user ? user.name : ""}
            style={{ aspectRatio: "1/1" }}
          />
        </div>
        <div className="profile-info">
          <div className="profile-label">Email:</div>
          <div className="profile-value">{user ? user.email : ""}</div>
          <div className="profile-label">Name:</div>
          <div className="profile-value">{user ? user.name : ""}</div>
          {user && user.role === "fan" && (
            <>
              <a className="profile-label" onClick={handleFollowing}>
                Following
              </a>
            </>
          )}
          <div>
            <button id="profile-logout" onClick={handleLogout}>
              Logout
            </button>
            {user && (user.role === "artist" || user.role === "venue") && (
              <button id="profile-stripe" onClick={handleSubscription}>
                Manage Subscription
              </button>
            )}
            <button id="profile-delete" onClick={handleLogout}>
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
