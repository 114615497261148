import React, { useEffect, useState } from "react";
import "../styles/messaging.css";
import {
  getBooking,
  getMessages,
  getUser,
  sendMessage,
} from "../helpers/firestore";
import { LuSend } from "react-icons/lu";

const MessagingPage = (props) => {
  const [messages, setMessages] = useState([]);
  const [booking, setBooking] = useState({});
  const [partner, setPartner] = useState({});
  const [message, setMessage] = useState("");
  const [unsubscribe, setUnsubscribe] = useState(null);

  const handleSend = async (event) => {
    event.preventDefault();
    try {
      await sendMessage(props.selected, {
        content: message,
        sender: props.user.role,
        time: new Date(),
      });
      setMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchBooking = async () => {
      console.log(props);
      try {
        const data = await getBooking(props.selected);
        setBooking({
          ...data,
          messages: undefined,
          dates: new Date(data.dates.seconds * 1000).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "short", day: "numeric" }
          ),
        });
        if (props.user.role === "band") {
          const partner = await getUser(data.venue);
          setPartner(partner);
        } else {
          const partner = await getUser(data.band);
          setPartner(partner);
        }

        setUnsubscribe(
          getMessages(
            props.selected,
            (data) => {
              setMessages(data);
            },
            []
          )
        );
      } catch (error) {
        console.error(error);
      }
    };
    if (props.selected) {
      fetchBooking();
    }
  }, [props.selected]);

  return (
    <div className="messaging-page">
      {props.selected && props.user.role === "band" && (
        <div className="initial-message">
          You received a request for {booking.dates.seconds} from {partner.name}
        </div>
      )}
      {props.selected && props.user.role === "venue" && (
        <div className="initial-message">
          You sent a request for {booking.dates} to {partner.name}
        </div>
      )}
      {!props.selected && (
        <div className="initial-message">
          Select a conversation to start messaging
        </div>
      )}
      <div className="message-wrapper">
        {props.selected && (
          <div className="messages">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`message ${
                  message.sender === props.user.role
                    ? "self-message"
                    : "partner-message"
                }`}
              >
                {message.content}
              </div>
            ))}
          </div>
        )}
      </div>
      {props.selected && (
        <form onSubmit={handleSend} className="message-input-wrapper">
          <input
            type="text"
            name="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            className="message-input"
            placeholder="Write a message..."
          />
          <button>
            <LuSend />
          </button>
        </form>
      )}
    </div>
  );
};

export default MessagingPage;
