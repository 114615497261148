import React from "react";
import "../styles/homeCard.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function HomeCard(props) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="home-card"
    >
      <Link to={`/event/${props.id}`} style={{ textDecoration: "none" }}>
        <div className="home-card-image-wrapper">
          <img
            src={props.image}
            alt={props.title}
            className="home-card-image"
          />
          <div className="card-overlay-wrapper">
            <div className="card-overlay-text">{props.title}</div>
          </div>
        </div>
        <div className="home-card-text">
          <div>{props.date}</div>
          <div className="home-card-secondary-text">
            <div className="home-card-description">{props.description}</div>
            <a className="location-text"> {props.location}</a>
          </div>
        </div>
      </Link>
    </motion.div>
  );
}

export default HomeCard;
