import React, { useEffect, useState } from "react";
import "../styles/inbox.css";
import { getBookings, getSelf } from "../helpers/firestore";
import ConvoThumbnail from "../components/convoThumbnail";
import MessagingPage from "./messagingPage";
import BookingDetails from "../components/bookingDetails";
import { auth } from "../helpers/firebase";

const InboxPage = () => {
  const [bookings, setBookings] = useState([]);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [selected, setSelected] = useState(null);
  const [limit, setLimit] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      const fetchBookings = async () => {
        try {
          const data = await getBookings(limit);
          setBookings(data);
        } catch (error) {
          console.error(error);
        }
      };

      const fetchSelf = async () => {
        try {
          const user = await getSelf();
          setUser(user);
        } catch (error) {
          console.error(error);
        }
      };
      fetchBookings();
      fetchSelf();
    });
  }, [limit]);

  return (
    <div className="inbox-page">
      <div className="inbox">
        <div className="inbox-header">
          <div className="inbox-title">Inbox</div>
        </div>
        {bookings.map((booking) => (
          <ConvoThumbnail
            key={booking.id}
            bookingID={booking.id}
            handler={setSelected}
            partner={user.role === "venue" ? booking.band : booking.venue}
            message={{
              content: booking.messages[booking.messages.length - 1].content,
              date: new Date(
                booking.messages[booking.messages.length - 1].time.seconds *
                  1000
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
            }}
            selected={selected}
          />
        ))}
      </div>
      <MessagingPage user={user} selected={selected} />
      <BookingDetails bookingID={selected} user={user} />
    </div>
  );
};

export default InboxPage;
