import React, { useEffect } from "react";
import "../styles/venue.css";
import { useParams } from "react-router-dom";
import { getUser } from "../helpers/firestore";
import { ThreeDots } from "react-loader-spinner";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { getSelf, follow, unfollow } from "../helpers/firestore";
import { auth } from "../helpers/firebase";

function VenuePage() {
  let { id } = useParams();
  const [venue, setVenue] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [following, setFollowing] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await getUser(id);
        if (data) {
          setVenue(data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const loadUser = async () => {
      try {
        const userData = await getSelf();
        if (userData) {
          setUser(userData);
          if (userData.following.includes(id)) {
            setFollowing(true);
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadData();
    loadUser();
  }, []);

  return (
    <div className="venue-page">
      {venue ? (
        <div className="venue-wrapper">
          <a id="close" href="/">
            X
          </a>
          <div className="image-container">
            <img src={venue.image} alt={venue.name} className="venue-image" />
          </div>
          <div className="venue-details">
            <div className="venue-header">{venue.name}</div>
            <div className="venue-subheader">{venue.address}</div>
            <div className="venue-description">{venue.description}</div>
            {user ? (
              following ? (
                <button
                  onClick={() => {
                    unfollow(auth.currentUser.uid, venue.id);
                    setFollowing(false);
                  }}
                  id="follow"
                >
                  Unfollow -
                </button>
              ) : (
                <button
                  onClick={() => {
                    follow(auth.currentUser.uid, venue.id);
                    setFollowing(true);
                  }}
                  id="follow"
                >
                  Follow +
                </button>
              )
            ) : (
              <button
                onClick={() => window.location.replace("/login")}
                id="follow"
              >
                Follow +
              </button>
            )}
            <div className="map-container">
              <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Map
                  style={{ width: "100%", height: "100%" }}
                  defaultCenter={{ lat: venue.loc._lat, lng: venue.loc._long }}
                  defaultZoom={15}
                  gestureHandling={"greedy"}
                  disableDefaultUI={true}
                />
              </APIProvider>
            </div>
          </div>
        </div>
      ) : (
        <ThreeDots color="#ed46c7" height={80} width={80} />
      )}
    </div>
  );
}

export default VenuePage;
