import React from "react";
import "../styles/venueRegistration.css";
import { uploadImage } from "../helpers/storage";
import { addUser } from "../helpers/firestore";
import { register } from "../helpers/auth";
import Autocomplete from "react-google-autocomplete";
import { GeoPoint } from "firebase/firestore";

const VenueRegisterPage = () => {
  const [venueName, setVenueName] = React.useState("");
  const [venueAddress, setVenueAddress] = React.useState("");
  const [venueCapacity, setVenueCapacity] = React.useState("");
  const [venueZip, setVenueZip] = React.useState("");
  const [venueGeopoint, setVenueGeopoint] = React.useState(new GeoPoint(0, 0));
  const [venueEmail, setVenueEmail] = React.useState("");
  const [venuePhone, setVenuePhone] = React.useState("");
  const [venueWebsite, setVenueWebsite] = React.useState("");
  const [venueDescription, setVenueDescription] = React.useState("");
  const [venueImage, setVenueImage] = React.useState("");
  const [venuePassword, setVenuePassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handleVenueRegistration = async (event) => {
    event.preventDefault();
    const values = [
      venueName,
      venueAddress,
      venueCapacity,
      venueZip,
      venueEmail,
      venuePhone,
      venueWebsite,
      venueDescription,
      venueImage,
      venuePassword,
    ];

    if (values.some((value) => value === "")) {
      setError("Please fill out all fields");
    } else {
      const imageUrl = await uploadImage(venueImage, venueEmail);
      if (imageUrl) {
        const venue = {
          name: venueName,
          address: venueAddress,
          capacity: venueCapacity,
          zip: venueZip,
          email: venueEmail,
          phone: venuePhone,
          website: venueWebsite,
          description: venueDescription,
          image: imageUrl,
          role: "venue",
          loc: venueGeopoint,
          following: [],
          saved: [],
        };
        await register(venueEmail, venuePassword);
        await addUser(venue);
        window.location.href = "/profile";
      } else {
        setError("Image upload failed");
      }
    }
  };

  return (
    <div className="venue-registration">
      <form className="venue-registration-form">
        <input
          placeholder="Venue Name"
          name="venueName"
          className="venue-registration-form-input"
          value={venueName}
          onChange={(e) => setVenueName(e.target.value)}
        ></input>
        <input
          placeholder="Email"
          name="venueEmail"
          className="venue-registration-form-input"
          type="email"
          value={venueEmail}
          onChange={(e) => setVenueEmail(e.target.value)}
        ></input>
        <input
          placeholder="Password"
          name="password"
          className="venue-registration-form-input"
          type="password"
          value={venuePassword}
          onChange={(e) => setVenuePassword(e.target.value)}
        ></input>
        <input
          placeholder="Phone"
          name="venuePhone"
          className="venue-registration-form-input"
          value={venuePhone}
          onChange={(e) => setVenuePhone(e.target.value)}
        ></input>
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            setVenueAddress(place.formatted_address);
            setVenueGeopoint(
              new GeoPoint(
                place.geometry.location.lat(),
                place.geometry.location.lng()
              )
            );
            setVenueZip(
              place.address_components[place.address_components.length - 1]
                .long_name
            );
          }}
          options={{
            types: ["address"],
            fields: ["formatted_address", "geometry", "address_components"],
          }}
          className="venue-registration-form-input"
          placeholder="Address"
          inputAutocompleteValue="one-time-code"
        />
        <input
          placeholder="Capacity"
          name="venueCapacity"
          className="venue-registration-form-input"
          value={venueCapacity}
          onChange={(e) => setVenueCapacity(e.target.value)}
        ></input>
        <input
          placeholder="Website"
          name="venueWebsite"
          className="venue-registration-form-input"
          value={venueWebsite}
          onChange={(e) => setVenueWebsite(e.target.value)}
        ></input>
        <textarea
          placeholder="Description"
          name="venueDescription"
          className="venue-registration-form-input"
          value={venueDescription}
          onChange={(e) => setVenueDescription(e.target.value)}
          id="description"
        ></textarea>
        <input
          label="Image"
          placeholder="Choose Image"
          accept="image/*"
          type="file"
          name="venueImage"
          className="venue-registration-form-input"
          onChange={(e) => setVenueImage(e.target.files[0])}
        ></input>
        <div className="venue-registration-form-detail">
          We recommend using a square image for best acuity
        </div>
        <button
          className="venue-registration-form-button"
          onClick={handleVenueRegistration}
        >
          Complete Registration
        </button>
        <div className="error-message">{error}</div>
      </form>
    </div>
  );
};

export default VenueRegisterPage;
