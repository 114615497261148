import React, { useEffect, useState } from "react";
import SearchBar from "../components/searchBar";
import FilterTag from "../components/filterTag";
import HomeCard from "../components/homeCard";
import { getEvents } from "../helpers/firestore";
import "../styles/home.css";
import { ThreeDots } from "react-loader-spinner";
import { AnimatePresence, motion } from "framer-motion";

const dummyTags = [
  "Rock",
  "Acoustic",
  "Pop",
  "Modern",
  "Folk",
  "Country",
  "Other",
  "Blues",
  "Jazz",
  "80's",
  "90's",
  "70's",
];

const HomePage = () => {
  const [events, setEvents] = useState(null);
  const [tags, setTags] = useState([]);
  const [eventsRaw, setEventsRaw] = useState(null);

  function toggleTag(tag) {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const data = await getEvents();
      if (data) {
        setEvents(data);
        setEventsRaw(data);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (tags.length === 0) {
      setEvents(eventsRaw);
    } else {
      const filteredEvents = eventsRaw.filter((event) => {
        return tags.some((tag) => event.tags.includes(tag));
      });
      setEvents(filteredEvents);
    }
  }, [tags]);

  return (
    <div className="home">
      <SearchBar />
      <div className="filters">
        {dummyTags.map((tag) => (
          <FilterTag tag={tag} toggle={toggleTag} />
        ))}
      </div>
      <motion.div
        className="home-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {events ? (
          events.length === 0 ? (
            <h1>No events found</h1>
          ) : (
            <AnimatePresence className="home-animated-presence">
              {events.map((event) => (
                <HomeCard
                  title={event.performer}
                  description={event.description}
                  image={event.image}
                  location={event.location}
                  id={event.id}
                />
              ))}
            </AnimatePresence>
          )
        ) : (
          <ThreeDots color="#ed46c7" height={100} width={80} />
        )}
      </motion.div>
    </div>
  );
};

export default HomePage;
