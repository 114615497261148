import { firestore } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  doc,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth } from "./firebase";

export const getUser = async (userID) => {
  const userDoc = await getDoc(doc(firestore, "users", userID));
  if (userDoc) {
    const data = userDoc.data();
    const userType = data.role;
    if (userType === "venue" || userType === "band") {
      return userDoc.data();
    } else {
      throw new Error("User is not a band or venue");
    }
  }
  return null;
};

export const getSelf = async () => {
  const user = auth.currentUser;
  if (!user) {
    return null;
  }
  const userDoc = await getDoc(doc(firestore, "users", user.uid));
  return userDoc.data();
};

export const addBand = async (band) => {
  await addDoc(collection(firestore, "users"), band);
};

export const deleteBand = async (bandID) => {
  await deleteDoc(doc(firestore, "users", bandID));
};

export const getVenues = async () => {
  const venuesList = [];
  const venuesCollection = collection(firestore, "users").where(
    "role",
    "==",
    "venue"
  );
  const venuesSnapshot = await getDocs(venuesCollection);
  venuesSnapshot.forEach((doc) => {
    venuesList.push(doc.data());
  });
  return venuesList;
};

const haversineDistance = (loc1, loc2) => {
  {
    /* function to calculate the distance between two locations using the haversine formula */
  }
  const R = 3958.8;
  {
    /* radius of the earth in miles */
  }
  const φ1 = (loc1.lat * Math.PI) / 180;
  const φ2 = (loc2.lat * Math.PI) / 180;
  const Δφ = ((loc2.lat - loc1.lat) * Math.PI) / 180;
  const Δλ = ((loc2.lng - loc1.lng) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

export const getBands = async ({ filters }) => {
  console.log(filters);
  try {
    let bandsList = [];
    let bandsSnapshot;
    const bandsCollection = collection(firestore, "users");
    if (!filters || !filters.genres) {
      bandsSnapshot = await getDocs(
        query(bandsCollection, where("role", "==", "band"))
      );
    } else {
      bandsSnapshot = await getDocs(
        query(
          bandsCollection,
          where("role", "==", "band"),
          where("tags", "array-contains-any", filters.genres)
        )
      );
    }
    bandsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (filters && filters.loc && filters.maxDistance) {
        if (haversineDistance(filters.loc, data.loc) <= data.maxDistance) {
          const id = doc.id;
          const band = { ...data, id };
          bandsList.push(band);
        } else {
          console.log("filtered out by location");
        }
      } else {
        const id = doc.id;
        const band = { ...data, id };
        console.log(band.tags);
        bandsList.push(band);
      }
    });
    return bandsList;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addUser = async (data) => {
  if (data.email) {
    const querySnapshot = await getDocs(
      collection(firestore, "users"),
      where("email", "==", data.email)
    );
    if (!querySnapshot.empty) {
      return;
    }
  }
  await setDoc(doc(firestore, "users", auth.currentUser.uid), data);
};

export const getBookings = (limit) => {
  let num = 1;
  return new Promise(async (resolve, reject) => {
    try {
      const uid = auth.currentUser.uid;
      let bookingsList = [];
      const bookingsCollection = collection(firestore, "bookings");
      const bookingsSnapshot = await getDocs(
        query(
          bookingsCollection,
          where("venue", "==", uid, "band", "==", uid, "op", "||"),
          orderBy("dates", "desc")
        )
      );
      bookingsSnapshot.forEach((doc) => {
        if (limit) {
          if (num <= 5) {
            const data = doc.data();
            const id = doc.id;
            const booking = { ...data, id };
            bookingsList.push(booking);
            num++;
          } else {
            resolve(bookingsList);
          }
        } else {
          const data = doc.data();
          const id = doc.id;
          const booking = { ...data, id };
          bookingsList.push(booking);
        }
      });
      resolve(bookingsList);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfilePhoto = (userID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userID));
      resolve(userDoc.data().photo);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserType = async (userID) => {
  const userDoc = await getDoc(doc(firestore, "users", userID));
  return userDoc.data().role;
};

export const getMessages = async (bookingID, callback) => {
  const bookingDoc = doc(firestore, "bookings", bookingID);
  const unsubscribe = onSnapshot(bookingDoc, (doc) => {
    const data = doc.data();
    if (data) {
      callback(data.messages);
    }
  });
  return unsubscribe;
};

export const createBooking = async (booking) => {
  await addDoc(collection(firestore, "bookings"), booking);
};

export const sendMessage = async (bookingID, message) => {
  if (!message) {
    return;
  }
  if (getSubscriptionStatus()) {
    const bookingDoc = doc(firestore, "bookings", bookingID);
    const booking = await getDoc(bookingDoc);
    const data = booking.data();
    data.messages.push(message);
    await setDoc(bookingDoc, data);
  } else {
    console.error("User is not subscribed");
    window.location.href = "/checkout";
  }
};

export const getBooking = async (bookingID) => {
  const bookingDoc = await getDoc(doc(firestore, "bookings", bookingID));
  return bookingDoc.data();
};

export const updateStatus = async (bookingID, status) => {
  const bookingDoc = doc(firestore, "bookings", bookingID);
  const booking = await getDoc(bookingDoc);
  const data = booking.data();
  data.status = status;
  await setDoc(bookingDoc, data);
};

export const follow = async (userID, bandID) => {
  const userDoc = doc(firestore, "users", userID);
  const user = await getDoc(userDoc);
  const data = user.data();
  data.following.push(bandID);
  await setDoc(userDoc, data);
};

export const unfollow = async (userID, bandID) => {
  const userDoc = doc(firestore, "users", userID);
  const user = await getDoc(userDoc);
  const data = user.data();
  data.following = data.following.filter((id) => id !== bandID);
  await setDoc(userDoc, data);
};

export const bookingToEvent = async (bookingID) => {
  const bookingDoc = doc(firestore, "bookings", bookingID);
  const booking = await getDoc(bookingDoc);
  const data = booking.data();
  const band = await getUser(data.band);
  const venue = await getUser(data.venue);
  const event = {
    date: data.dates,
    location: venue.name,
    venue: data.venue,
    performer: band.name,
    band: data.band,
    featured: "false",
    price: 0,
    saves: 0,
    tags: band.tags,
    image: band.image,
    description: band.description,
    loc: venue.loc,
  };
  console.log(event);
  const eventID = await addEvent(event);
  await setDoc(bookingDoc, { ...data, eventID });
};

export const getFollowing = async () => {
  const userDoc = await getSelf();
  return userDoc.following;
};

export const getFollowingData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const following = await getFollowing();
      const followingData = [];
      for (const id of following) {
        const user = await getUser(id);
        followingData.push(user);
      }
      resolve(followingData);
    } catch (error) {
      reject(error);
    }
  });
};

export const getEvents = async (filters) => {
  if (filters) {
    const eventsList = [];
    const eventsCollection = collection(firestore, "events");
    const eventsSnapshot = await getDocs(
      query(
        eventsCollection,
        where("date", ">=", filters.date),
        where("location", "==", filters.location),
        limit(20)
      )
    );
    eventsSnapshot.forEach((doc) => {
      const data = doc.data();
      const id = doc.id;
      const event = { ...data, id: id };
      eventsList.push(event);
    });
    return eventsList;
  } else {
    const eventsList = [];
    const eventsCollection = collection(firestore, "events");
    const eventsSnapshot = await getDocs(eventsCollection);
    eventsSnapshot.forEach((doc) => {
      const data = doc.data();
      const id = doc.id;
      const event = { ...data, id: id };
      eventsList.push(event);
    });
    return eventsList;
  }
};

export const getEvent = async (eventID) => {
  const eventDoc = await getDoc(doc(firestore, "events", eventID));
  const data = eventDoc.data();
  data.id = eventDoc.id;
  return data;
};

export const addEvent = async (event) => {
  const eventsCollection = collection(firestore, "events");
  const newEvent = await addDoc(eventsCollection, event);
  return newEvent.id;
};

export const deleteEvent = async (eventID) => {
  await deleteDoc(doc(firestore, "events", eventID));
};

export const saveEvent = async (eventID) => {
  const userDoc = doc(firestore, "users", auth.currentUser.uid);
  const user = await getDoc(userDoc);
  const data = user.data();
  data.saved.push(eventID);
  await setDoc(userDoc, data);
};

export const unsaveEvent = async (eventID) => {
  const userDoc = doc(firestore, "users", auth.currentUser.uid);
  const user = await getDoc(userDoc);
  const data = user.data();
  data.saved = data.saved.filter((id) => id !== eventID);
  await setDoc(userDoc, data);
};

export const checkSaved = async (eventID) => {
  const userDoc = doc(firestore, "users", auth.currentUser.uid);
  const user = await getDoc(userDoc);
  return user.data().savedEvents.includes(eventID);
};

export const getSavedEvents = async (userID, limit) => {
  const userDoc = await getDoc(doc(firestore, "users", userID));
  let savedEvents = [];
  for (const id of userDoc.data().saved) {
    if (savedEvents.length >= limit) {
      break;
    }
    const event = await getEvent(id);
    savedEvents.push(event);
  }
  return savedEvents;
};

export const getFollowingEvents = async (userID, limit) => {
  const following = await getFollowing(userID);
  const followingEvents = [];
  const eventsCollection = collection(firestore, "events");
  const bandLimit = 3;
  let bandNum = 0;
  for (const id of following) {
    if (followingEvents.length >= limit) {
      break;
    }
    const events = await getDocs(
      query(eventsCollection, where("band", "==", id), where("venue", "==", id))
    );
    for (const event of events) {
      if (bandNum >= bandLimit) {
        break;
      }
      followingEvents.push(event.data());
      bandNum++;
    }
  }
  return followingEvents;
};

export const setFeatured = async (eventID) => {
  const eventDoc = doc(firestore, "events", eventID);
  const event = await getDoc(eventDoc);
  const data = event.data();
  data.feature = true;
  await setDoc(eventDoc, data);
};

export const getSubscriptionStatus = async () => {
  const user = await getSelf();
  if (!user) {
    return false;
  } else {
    return user.subscriptionStatus === "active";
  }
};

export const setStripeID = async (session_id) => {
  const user = auth.currentUser;
  if (!user) {
    console.error("No user ID");
    return;
  }
  try {
    console.log(user.uid);
    const response = await fetch(
      "https://jammin-together.ue.r.appspot.com/set-stripe-id",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: session_id, user_id: user.uid }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const openCheckoutPortal = async (uid) => {
  const user = await getUser(uid);
  const sessionId = user.stripeId;
  console.log(sessionId);
  if (!sessionId) {
    console.error("No session ID");
    return;
  }
  try {
    const response = await fetch(
      "https://jammin-together.ue.r.appspot.com/create-portal-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: sessionId,
        }),
      }
    );
    const data = await response.json();
    console.log(data);
    window.location.href = data.url;
  } catch (error) {
    console.error(error);
  }
};

export const searchEvents = async (search) => {
  for (const key in search) {
    if (search[key] === "none") {
      search[key] = null;
    }
  }

  if (search.genre) {
    search.genre = search.genre.split(",");
  }

  const eventsList = [];
  const eventsCollection = collection(firestore, "events");
  let eventsSnapshot;
  if ((!search.date && !search.location && !search.genre) || !search.genre) {
    console.log("no search");
    eventsSnapshot = await getDocs(
      query(eventsCollection, limit(10), orderBy("date"))
    );
  } else {
    console.log(search);
    eventsSnapshot = await getDocs(
      query(
        eventsCollection,
        where("tags", "array-contains-any", search.genre),
        limit(10),
        orderBy("date")
      )
    );
  }
  eventsSnapshot.forEach((doc) => {
    const data = doc.data();
    console.log(data.date.seconds);
    if (search.date) {
      {
        /* check if the event is within 24 hours (86400 seconds) of the search date */
      }
      if (
        !(
          data.date.seconds >= search.date - 86400 &&
          data.date.seconds <= search.date + 86400
        )
      ) {
        console.log("filtered out by date");
        console.log(search.date);
        return;
      }
    }
    if (search.location) {
      if (data.location !== search.location) {
        return;
      }
    }
    const id = doc.id;
    const event = { ...data, id: id };
    eventsList.push(event);
  });
  return eventsList;
};

export const autofillVenues = async (search) => {
  const venuesList = [];
  const venuesSnapshot = await getDocs(
    query(
      collection(firestore, "users"),
      where("role", "==", "venue"),
      where("name", "<=", search),
      orderBy("name"),
      limit(5)
    )
  );
  venuesSnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.name.toLowerCase().includes(search.toLowerCase())) {
      venuesList.push(data);
    }
  });
  return venuesList;
};
