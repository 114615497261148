import React, { useEffect } from "react";
import "../styles/following.css";
import FollowingUser from "../components/followingUser";
import { auth } from "../helpers/firebase";
import { getFollowingData, getSavedEvents } from "../helpers/firestore";
import { ThreeDots } from "react-loader-spinner";
import SavedThumbnail from "../components/savedThumbnail";

const FollowingPage = () => {
  const [followingData, setFollowingData] = React.useState([]);
  const [savedEvents, setSavedEvents] = React.useState([]);

  useEffect(() => {
    const fetchFollowingData = async () => {
      try {
        const data = await getFollowingData(auth.currentUser.uid);
        setFollowingData(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSavedEvents = async () => {
      try {
        const data = await getSavedEvents(auth.currentUser.uid, 5);
        setSavedEvents(data);
      } catch (error) {
        console.error(error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchFollowingData();
        fetchSavedEvents();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="following-page">
      <div className="following-body">
        <div className="following-users">
          <div className="following-users-title">Following</div>
          <div className="following-users-list">
            {followingData.length === 0 ? (
              <ThreeDots className="loader" color="#ED46C7" />
            ) : (
              <div className="following-user">
                {followingData.map((user) => (
                  <FollowingUser key={user.id} user={user} />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="following-events">
          <div className="following-events-title">Saved Events</div>
          <div className="following-events-list">
            {savedEvents.length === 0 ? (
              <div>No saved events found</div>
            ) : (
              <div className="following-event">
                {savedEvents.map((event) => (
                  <SavedThumbnail key={event.id} event={event} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowingPage;
