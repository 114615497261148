import React from "react";
import "../styles/filterTag.css";

const FilterTag = ({ tag, toggle }) => {
  const [tagSelected, setTagSelected] = React.useState(false);
  let tagAction;
  if (toggle) {
    tagAction = (tag) => {
      toggle(tag.toLowerCase());
      setTagSelected(!tagSelected);
    };
  } else {
    tagAction = (tag) => {
      window.location.href = `/search/none/none/${tag.toLowerCase()}`;
    };
  }
  return (
    <div className="filter-tag">
      <button
        onClick={() => tagAction(tag)}
        style={{
          backgroundColor: tagSelected ? "#ed46c7" : "#080e20",
          color: tagSelected ? "white" : "#ed46c7",
        }}
      >
        #{tag}
      </button>
    </div>
  );
};

export default FilterTag;
