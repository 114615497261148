import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCqXAQiFg-dFdOMYc7QYAWP7hBRc5CekUE",
  authDomain: "jammin-together-4c0c2.firebaseapp.com",
  databaseURL: "https://jammin-together-4c0c2-default-rtdb.firebaseio.com",
  projectId: "jammin-together-4c0c2",
  storageBucket: "jammin-together-4c0c2.appspot.com",
  messagingSenderId: "449384682760",
  appId: "1:449384682760:web:bdf401e6fed8524f6bc8bd",
  measurementId: "G-VMJ0GRF6CK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();

export const firestore = getFirestore(app);
export const db = getDatabase(app);

export const googleProvider = new GoogleAuthProvider();

export const storage = getStorage();
