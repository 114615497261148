import React from "react";
import "../styles/partners.css";

const PartnersPage = () => {
  return (
    <div className="partners-page">
      <div
        className="partners-card"
        onClick={() =>
          (window.location =
            "https://www.fallenraider.com/?utm_campaign=JamminTogether&utm_source=JamminApp")
        }
      >
        <h1>Fallen Raider Audio</h1>
        <img src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F3f533f6ff18ac855ec9b65f4e96478eb.cdn.bubble.io%2Ff1683093355423x175131557391116300%2Ffallen%2520raider%2520audio%2520logo.jpg?w=384&h=384&auto=compress&dpr=1.25&fit=max" />
        <p>
          Fallen Raider Audio is a Veteran owned and operated pro audio company
          in Central Texas that employs disabled veterans and trains them to
          build high-quality guitar pedals & audio gear in service to our
          mission - reducing hopelessness and homelessness in the veteran
          community.
        </p>
        <p>
          Our strategic intent is to make Fallen Raider Audio a leader in
          high-quality pro audio gear that will not only excel in the industry
          but also provide income and counseling for veterans who suffer from
          PTSD. We intend to make Fallen Raider Audio an industry-standard while
          changing the lives of veterans.
        </p>
      </div>
      <div
        className="partners-card"
        onClick={() =>
          (window.location =
            "https://helmaudio.com/?utm_campaign=JamminTogether&utm_source=JamminApp")
        }
      >
        <h1>HELM Audio</h1>
        <img src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F3f533f6ff18ac855ec9b65f4e96478eb.cdn.bubble.io%2Ff1683093693836x558922597572680800%2Fhelm%2520audio%2520logo.jpg?w=384&h=384&auto=compress&dpr=1.25&fit=max" />
        <p>
          HELM Audio is an award-winning audio innovation & technology company.
          Founded in 2017 by a team of industry veterans with a passion to
          deliver better sound quality to ultimately help everyone hear better.
          We are developing new transformational audio products including
          hearing protection and a line-up of hearing aid products that will
          change lives!
        </p>
      </div>
      <div
        className="partners-card"
        onClick={() =>
          (window.location =
            "https://www.warriormusicfoundation.org/?utm_campaign=JamminTogether&utm_source=JamminApp")
        }
      >
        <h1>Warrior Music Foundation</h1>
        <img src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F3f533f6ff18ac855ec9b65f4e96478eb.cdn.bubble.io%2Ff1683093745369x553671220469056600%2Fwarriror%2520music%2520foundation%2520logo.jpg?w=384&h=384&auto=compress&dpr=1.25&fit=max" />
        <p>
          Warrior Music Foundation is dedicated to helping limit the impact of
          PTSD and reduce the number of suicides across the active duty,
          reserve, veteran and law enforcement population by maximizing the
          healing power of music in a facilitated environment. We are equally
          focused on aiding military family members who are also dealing with
          the results of PTSD, deployments and life disruptions. We have
          assembled a team committed to serving our nation’s sons and daughters
          by offering an approach that is centered around music lessons in both
          individual and group settings. Veterans, military members and their
          families can enroll in lessons that are tailored to their skill level
          – from beginner to virtuoso. The entire program will be facilitated by
          an on-staff music therapist who will ensure the instructors and
          students are both aligned to maximize the output of the lessons. Our
          therapists are a resource for the military members, veterans and their
          families to use as much or little as they desire. All of our services
          are also available to law enforcement and first responders.
        </p>
      </div>
    </div>
  );
};

export default PartnersPage;
