import React, { useState, useEffect } from "react";
import "../styles/bandDiscover.css";
import { getBands, getSelf } from "../helpers/firestore";
import FilterTag from "../components/filterTag";
import DiscoverCard from "../components/discoverCard";

const dummyTags = [
  "Rock",
  "Acoustic",
  "Pop",
  "Modern",
  "Folk",
  "Country",
  "Other",
  "Blues",
  "80’s",
  "90’s",
  "70’s",
];

const BandDiscoverPage = () => {
  const [bands, setBands] = useState([]);
  const [tags, setTags] = useState([]);
  const [bandsRaw, setBandsRaw] = useState([]);

  function toggleTag(tag) {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const user = await getSelf();
      if (user) {
        const data = await getBands({ genres: tags, loc: user.loc });
        console.log(data);
        if (data) {
          const filteredData = data.filter((entry) => entry.image);
          setBands(filteredData);
          setBandsRaw(filteredData);
        }
      } else {
        const data = await getBands({ genres: tags });
        if (data) {
          const filteredData = data.filter((entry) => entry.image);
          setBands(filteredData);
          setBandsRaw(filteredData);
        }
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (tags.length === 0) {
      setBands(bandsRaw);
    } else {
      const filteredBands = bandsRaw.filter((band) => {
        console.log(band);
        if (!band.tags) {
          return false;
        }
        return tags.some((tag) => band.tags.includes(tag));
      });
      setBands(filteredBands);
    }
  }, [tags]);

  return (
    <div className="band-discover-page">
      <div className="band-discover-body">
        <div className="band-discover-bands">
          <div className="band-discover-tags">
            {dummyTags.map((tag) => (
              <FilterTag key={tag} tag={tag} toggle={toggleTag} />
            ))}
          </div>
          <div className="band-discover-cards">
            {bands.map((band) => (
              <DiscoverCard key={band.id} band={band} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandDiscoverPage;
