import React from "react";
import "../styles/login.css";
import { login, resetPassword, googleLogin } from "../helpers/auth";

const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent page reload
    if (email === "" || password === "") {
      setError("Email and password fields are required");
      return;
    }
    let result = await login(email, password);
    if (result) {
      setError(result);
    } else {
      setError("");
    }
  };

  const handleReset = async (event) => {
    event.preventDefault();
    window.location.href = "/reset";
  };

  const handleGoogleLogin = async (event) => {
    event.preventDefault(); // Prevent page reload
    let result = await googleLogin();
    if (result) {
      setError(result);
    } else {
      setError("");
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    window.location.href = "/register";
  };

  return (
    <div className="login-page">
      <form className="login-form">
        <input
          placeholder="Email"
          name="email"
          className="login-form-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          placeholder="Password"
          className="login-form-input"
          name="password"
          type="password" // Obfuscate the entered text
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <button onClick={handleLogin}>Login</button>
        <button onClick={handleRegister}>Register</button>
        <div>or</div>
        <button onClick={handleGoogleLogin}>
          <div className="google-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              height={18}
              width={18}
            >
              <path
                fill="#4285f4"
                d="M24.12 25c2.82-2.63 4.07-7 3.32-11.19H16.25v4.63h6.37A5.26 5.26 0 0 1 20.25 22z"
              ></path>
              <path
                fill="#34a853"
                d="M5.62 21.31A12 12 0 0 0 24.12 25l-3.87-3a7.16 7.16 0 0 1-10.69-3.75z"
              ></path>
              <path
                fill="#fbbc02"
                d="M9.56 18.25c-.5-1.56-.5-3 0-4.56l-3.94-3.07a12.08 12.08 0 0 0 0 10.7z"
              ></path>
              <path
                fill="#ea4335"
                d="M9.56 13.69c1.38-4.32 7.25-6.82 11.19-3.13l3.44-3.37a11.8 11.8 0 0 0-18.57 3.43l3.94 3.07z"
              ></path>
            </svg>
            <div className="google-button-text">Continue with Google</div>
          </div>
        </button>
        {error && <button onClick={handleReset}>Reset Password</button>}
        <div className="error-message">{error}</div>
      </form>
    </div>
  );
};

export default LoginPage;
