import {
  getDownloadURL,
  uploadBytes,
  ref as storageRef,
} from "firebase/storage";
import { storage } from "./firebase";

export const uploadImage = (file, name) => {
  return new Promise((resolve, reject) => {
    const imageRef = storageRef(storage, `images/${name}`);
    uploadBytes(imageRef, file)
      .then((snapshot) => {
        getDownloadURL(imageRef)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
