import React, { useState, useEffect } from "react";
import "../styles/homeCard.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const DiscoverCard = ({ band }) => {
  return (
    <motion.div
      className="home-card"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Link to={`/booking/${band.id}`} style={{ textDecoration: "none" }}>
        <div className="home-card-image-wrapper">
          <img className="home-card-image" src={band.image} alt={band.name} />
          <div className="card-overlay-wrapper">
            <div className="card-overlay-text">{band.name}</div>
          </div>
        </div>
        <div className="home-card-text">
          <div className="home-card-description">{band.description}</div>
          <div className="home-card-secondary-text">
            <a className="location-text"> {band.location}</a>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default DiscoverCard;
