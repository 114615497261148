import { auth, googleProvider } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

export const getUser = () => {
  console.log(auth.currentUser);
  return auth.currentUser;
};

export const logout = () => {
  auth.signOut();
};

const errorHandler = (error) => {
  if (error.code === "auth/user-not-found") {
    return "User not found";
  } else if (error.code === "auth/wrong-password") {
    return "Invalid password. Do you want to reset your password?";
  } else if (error.code === "auth/invalid-email") {
    return "Invalid email";
  } else if (error.code === "auth/email-already-in-use") {
    return "Email already in use";
  } else if (error.code === "auth/weak-password") {
    return "Password is too weak";
  } else if (error.code === "auth/invalid-credential") {
    return "Invalid credential. Do you want to reset your password?";
  } else {
    return error.message;
  }
};

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        const user = userCredential.user;
        window.location.href = "/profile";
      }
    );
  } catch (error) {
    console.log(error);
    return errorHandler(error);
  }
};

export const register = (email, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const resetPassword = (email) => {
  auth.sendPasswordResetEmail(email);
};

export const googleLogin = async () => {
  try {
    await signInWithPopup(auth, googleProvider).then((result) => {
      const credential = googleProvider.credentialFromResult(result);
    });
  } catch (error) {
    console.log(error);
    return errorHandler(error);
  }
};
