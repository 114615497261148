import React from "react";
import "../styles/bandRegistration.css";
import { uploadImage } from "../helpers/storage";
import { addUser } from "../helpers/firestore";
import { register } from "../helpers/auth";
import Autocomplete from "react-google-autocomplete";
import { GeoPoint } from "firebase/firestore";
import GenreAutocomplete from "../components/genreAutocomplete";
import FilterTag from "../components/filterTag";

const BandRegisterPage = () => {
  const [bandName, setbandName] = React.useState("");
  const [bandZip, setbandZip] = React.useState("");
  const [bandGeopoint, setbandGeopoint] = React.useState(new GeoPoint(0, 0));
  const [bandEmail, setbandEmail] = React.useState("");
  const [bandPhone, setbandPhone] = React.useState("");
  const [bandWebsite, setbandWebsite] = React.useState("");
  const [bandDescription, setbandDescription] = React.useState("");
  const [bandImage, setbandImage] = React.useState("");
  const [bandPassword, setbandPassword] = React.useState("");
  const [genres, setGenres] = React.useState([]);
  const [genre, setGenre] = React.useState("");
  const [error, setError] = React.useState("");

  const handlebandRegistration = async (event) => {
    event.preventDefault();
    const values = [
      bandName,
      bandZip,
      bandEmail,
      bandPhone,
      bandWebsite,
      bandDescription,
      bandImage,
      bandPassword,
      genres,
    ];
    if (values.some((value) => value === "")) {
      setError("Please fill out all fields");
    } else if (genres.length === 0) {
      setError("Please select at least one genre");
    } else if (bandImage.size > 5000000) {
      setError("Image must be less than 5MB");
    } else if (!bandImage.type.includes("image")) {
      setError("File must be an image");
    } else if (bandPassword.length < 6) {
      setError("Password must be at least 6 characters");
    } else if (bandEmail && !bandEmail.includes("@")) {
      setError("Invalid email");
    } else if (
      !bandWebsite.includes("spotify.com") &&
      !bandWebsite.includes("music.apple.com") &&
      !bandWebsite.includes("soundcloud.com") &&
      !bandWebsite.includes("youtube.com")
    ) {
      setError("Invalid website");
    } else {
      const imageUrl = await uploadImage(bandImage, bandEmail);
      if (imageUrl) {
        const band = {
          name: bandName,
          zip: bandZip,
          email: bandEmail,
          phone: bandPhone,
          website: bandWebsite,
          description: bandDescription,
          image: imageUrl,
          role: "band",
          loc: bandGeopoint,
          genres: genres,
          following: [],
          saved: [],
        };
        await register(bandEmail, bandPassword);
        await addUser(band);
        window.location.href = "/profile";
      } else {
        setError("Image upload failed");
      }
    }
  };

  return (
    <div className="band-registration">
      <form className="band-registration-form">
        <input
          placeholder="Band Name"
          name="bandName"
          className="band-registration-form-input"
          value={bandName}
          onChange={(e) => setbandName(e.target.value)}
        ></input>
        <input
          placeholder="Email"
          name="bandEmail"
          className="band-registration-form-input"
          type="email"
          value={bandEmail}
          onChange={(e) => setbandEmail(e.target.value)}
        ></input>
        <input
          placeholder="Password"
          name="password"
          className="band-registration-form-input"
          type="password"
          value={bandPassword}
          onChange={(e) => setbandPassword(e.target.value)}
        ></input>
        <input
          placeholder="Phone"
          name="bandPhone"
          className="band-registration-form-input"
          value={bandPhone}
          onChange={(e) => setbandPhone(e.target.value)}
        ></input>
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            setbandGeopoint(
              new GeoPoint(
                place.geometry.location.lat(),
                place.geometry.location.lng()
              )
            );
            setbandZip(
              place.address_components[place.address_components.length - 1]
                .long_name
            );
          }}
          options={{
            types: ["address"],
            fields: ["formatted_address", "geometry", "address_components"],
          }}
          className="band-registration-form-input"
          placeholder="Address"
          inputAutocompleteValue="one-time-code"
        />
        {genres.length > 0 && (
          <div className="band-registration-form-genres">
            {genres.map((genre) => (
              <FilterTag key={genre} tag={genre} toggle={() => {}} />
            ))}
          </div>
        )}
        <GenreAutocomplete
          updateParentGenre={setGenre}
          value={genre}
          updateParentGenres={setGenres}
          parentgenres={genres}
        />
        <input
          placeholder="Spotify/Apple Music/Soundcloud"
          name="bandWebsite"
          className="band-registration-form-input"
          value={bandWebsite}
          onChange={(e) => setbandWebsite(e.target.value)}
        ></input>
        <textarea
          placeholder="Description"
          name="bandDescription"
          className="band-registration-form-input"
          value={bandDescription}
          onChange={(e) => setbandDescription(e.target.value)}
          id="description"
        ></textarea>
        <input
          label="Image"
          placeholder="Choose Image"
          accept="image/*"
          type="file"
          name="bandImage"
          className="band-registration-form-input"
          onChange={(e) => setbandImage(e.target.files[0])}
        ></input>
        <div className="band-registration-form-detail">
          We recommend using a square image for best acuity
        </div>
        <div className="error-message">{error}</div>
        <button
          className="band-registration-form-button"
          onClick={handlebandRegistration}
        >
          Complete Registration
        </button>
      </form>
    </div>
  );
};

export default BandRegisterPage;
