import "./App.css";
import React from "react";
import HomePage from "./screens/homePage";
import HeaderBar from "./components/headerBar";
import LoginPage from "./screens/loginPage";
import RegisterPage from "./screens/registerPage";
import ProfilePage from "./screens/profilePage";
import EventPage from "./screens/eventPage";
import BandPage from "./screens/bandPage";
import VenueRegisterPage from "./screens/venueRegisterPage";
import BandRegisterPage from "./screens/bandRegisterPage";
import BookingPage from "./screens/bookingPage";
import InboxPage from "./screens/inboxPage";
import FollowingPage from "./screens/followingPage";
import CheckoutPage from "./screens/checkoutPage";
import FailedPage from "./screens/failedPage";
import BandDiscoverPage from "./screens/bandDiscoverPage";
import SearchResultsPage from "./screens/searchResultsPage";
import VenuePage from "./screens/venuePage";
import Terms from "./screens/terms";
import PrivacyPage from "./screens/privacyPage";
import FooterBar from "./components/footerBar";
import PartnersPage from "./screens/partnersPage";
import PassResetPage from "./screens/passResetPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./helpers/stripe";

function App() {
  const options = {};
  return (
    <div className="App">
      <HeaderBar />
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/event/:id" element={<EventPage />} />
            <Route path="/band/:id" element={<BandPage />} />
            <Route path="/register/band" element={<BandRegisterPage />} />
            <Route path="/register/venue" element={<VenueRegisterPage />} />
            <Route path="/booking/:bandid" element={<BookingPage />} />
            <Route path="/inbox" element={<InboxPage />} />
            <Route path="/following" element={<FollowingPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/discover/band" element={<BandDiscoverPage />} />
            <Route path="venue/:id" element={<VenuePage />} />
            <Route
              path="/search/:date/:location/:genre"
              element={<SearchResultsPage />}
            />
            <Route path="/checkout/canceled" element={<FailedPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/reset" element={<PassResetPage />} />
            <Route path="*">"404 Not Found"</Route>
          </Routes>
        </BrowserRouter>
      </Elements>
      <FooterBar />
    </div>
  );
}

export default App;
