import React, { useState, useEffect } from "react";
import {
  setStripeID,
  getSubscriptionStatus,
  getSelf,
  openCheckoutPortal,
} from "../helpers/firestore";
import "../styles/checkout.css";
import { set, update } from "firebase/database";
import { auth } from "../helpers/firebase";

const ProductDisplay = () => {
  const [subscribed, setSubscribed] = useState(false);
  const [user, setUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        window.location.href = "/login";
      } else {
        setSubscribed(await getSubscriptionStatus());
      }
    });
  }, []);

  const handlePortal = async (e) => {
    e.preventDefault();
    const portal = await openCheckoutPortal(user.uid);
  };

  return (
    <div className="checkout-page">
      <div className="product">
        <div className="description">
          <div className="checkout-title">All-access Subscription</div>
          <div className="checkout-desc">
            To keep Jammin' Together great, we charge a $20/month subscription
            fee for venues and bands.
          </div>
        </div>
      </div>
      {!subscribed ? (
        <form
          action="https://jammin-together.ue.r.appspot.com/create-checkout-session"
          method="POST"
        >
          {/* hidden field with the lookup_key of the subscription price */}
          <input
            type="hidden"
            name="lookup_key"
            value="price_1PTr8tDCHNXaksOuZU0dOVwN"
          />
          <button id="checkout-and-portal-button" type="submit">
            Checkout
          </button>
        </form>
      ) : (
        <div>
          <div className="description">
            You are already subscribed to Jammin' Together. To manage your
            subscription, click the button below.
          </div>
          <form onSubmit={handlePortal}>
            <button id="checkout-and-portal-button" type="submit">
              Manage your subscription
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

const SuccessDisplay = ({ sessionId }) => {
  const handleReturn = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(sessionId);
    try {
      const response = await fetch(
        "https://jammin-together.ue.r.appspot.com/create-portal-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            session_id: { sessionId },
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      window.location.href = data.url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="checkout-page">
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>
            We successfully processed your payment. Welcome to the platform!
          </h3>
        </div>
      </div>
      <button id="return" onClick={handleReturn}>
        Return to the home page
      </button>
      <form
        method="POST"
        action="https://jammin-together.ue.r.appspot.com/create-portal-session"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="session_id" value={sessionId} />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const CheckoutPage = (payment) => {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- come back when you're ready.");
    }
  }, []);

  if (!success && message === "") {
    return <ProductDisplay />;
  } else if (success && sessionId !== "") {
    let data;
    const handleSetStripeID = async () => {
      console.log("setting stripe id");
      try {
        const response = await fetch(
          "https://jammin-together.ue.r.appspot.com/get-subscription-id",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              session_id: { sessionId },
            }),
          }
        );
        data = response;
      } catch (error) {
        console.error(error);
      }
      await setStripeID(data);
    };
    handleSetStripeID();
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
};

export default CheckoutPage;
