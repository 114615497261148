import React, { useEffect } from "react";
import "../styles/header.css";
import {
  LuUser2,
  LuMessageSquare,
  LuBookmark,
  LuCompass,
} from "react-icons/lu";
import { getSelf } from "../helpers/firestore";
import { auth } from "../helpers/firebase";

const HeaderBar = () => {
  const [user, setUser] = React.useState(null);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const data = await getSelf();
        setUser(data);
      }
    });
  }, []);
  return (
    <div className="header">
      <a href="/">
        <div className="header-logo-container">
          <img
            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F3f533f6ff18ac855ec9b65f4e96478eb.cdn.bubble.io%2Ff1695334819564x524215378535499700%2FJWRLogo.webp?w=192&h=108&auto=compress&dpr=1.25&fit=max"
            alt="logo"
            className="header-logo"
          ></img>
        </div>
      </a>
      <div className="header-nav">
        {user && user.role === "venue" && (
          <a href="/discover/band">
            <div className="header-nav-item">
              <LuCompass />
            </div>
          </a>
        )}
        <a href="/inbox">
          <div className="header-nav-item">
            <LuMessageSquare />
          </div>
        </a>
        <a href="/following">
          <div className="header-nav-item">
            <LuBookmark />
          </div>
        </a>
        <a href="/profile">
          <div className="header-nav-item">
            <LuUser2 />
          </div>
        </a>
      </div>
    </div>
  );
};

export default HeaderBar;
