import React from "react";
import "../styles/terms.css";

const Terms = () => {
  return (
    <div className="terms">
      <h1>Terms and Conditions</h1>
      <p>Effective: November 30, 2021 </p>{" "}
      <p>
        Welcome! We’ve drafted these Terms of Service (which we call the
        “Terms”) so you’ll know the rules that govern our relationship with you
        as a user of our Services. Although we have tried our best to strip the
        legalese from the Terms, there are places where they may still read like
        a traditional contract. There’s a good reason for that: these Terms form
        a legally binding contract between you and Jammin’ Together LLC
        (“Jammin’ Together”). So please read them carefully. By using Jammin’
        Together or any of our other products or services that are subject to
        these Terms (which we refer to collectively as the “Services”), you
        agree to the Terms. Of course, if you don’t agree with them, then don’t
        use the Services. These Terms apply if you live in the United States or
        if your principal place of business is in the United States.{" "}
      </p>{" "}
      <p>
        {" "}
        ARBITRATION NOTICE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE A LITTLE
        LATER ON. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT
        ARBITRATION CLAUSE, YOU AND JAMMIN’ TOGETHER AGREE THAT DISPUTES BETWEEN
        US WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND
        JAMMIN’ TOGETHER WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION
        LAWSUIT OR CLASS-WIDE ARBITRATION. YOU HAVE THE RIGHT TO OPT OUT OF
        ARBITRATION AS EXPLAINED IN THAT ARBITRATION CLAUSE.{" "}
      </p>{" "}
      <p>
        Jammin’ Together may update or revise this Agreement (including any
        Jammin’ Together Policies) from time to time. You agree that you will
        review this Agreement periodically. You are free to decide whether or
        not to accept a modified version of this Agreement, but accepting this
        Agreement, as modified, is required for you to continue using the
        Services. You may have to click “accept” or “agree” to show your
        acceptance of any modified version of this Agreement. If you do not
        agree to the terms of this Agreement or any modified version of this
        Agreement, you must terminate your use of the Services, in which case
        you will no longer have access to your Account (as defined below).
        Except as otherwise expressly stated by Jammin’ Together, any use of the
        Services (e.g., the use of the Connecting Services (as defined herein),
        Promotion Services (as defined herein), or Payment Services (as defined
        herein)) is subject to the version of this Agreement in effect at the
        time of use.{" "}
      </p>{" "}
      <p>
        {" "}
        Jammin’ Together’s collection and use of personal information in
        connection with the Services is described in Jammin’ Together's Privacy
        Policy.
      </p>{" "}
      <h3>1. Account Eligibility; Your Responsibilities </h3>{" "}
      <p>
        By using our Services, you represent and warrant that: You are at least
        18 years old; You are legally qualified to enter a binding contract with
        Jammin’ Together; You are not on any list of individuals prohibited from
        conducting business with the United States; You are not prohibited by
        law from using our services; You do not have more than one account on
        our Services; and You have not previously been removed from our Services
        by us, unless you have our express written permission to create a new
        account. If at any time you cease to meet these requirements, you must
        immediately delete your account.{" "}
      </p>{" "}
      <p>
        {" "}
        You agree to: Comply with these Terms, and check this page from time to
        time to ensure you are aware of any change; Comply with all applicable
        laws, including without limitation, privacy laws, intellectual property
        laws, anti-spam laws, and regulatory requirements; Use the latest
        version of the Website and/or App; Treat other users in a courteous and
        respectful manner, both on and off our Services; Be respectful when
        communicating with any of our customer care representatives or other
        employees; Maintain a strong password and take reasonable measures to
        protect the security of your login information.{" "}
      </p>{" "}
      <p>
        You agree that you will not: Misrepresent your identity, age, current or
        previous positions, qualifications, or affiliations with a person or
        entity; Use the Services in a way that damages the Services or prevents
        their use by other users; Use our Services in a way to interfere with,
        disrupt or negatively affect the platform, the servers, or our Services’
        networks; Use our Services for any harmful, illegal, or nefarious
        purpose; Harass, bully, stalk, intimidate, assault, defame, harm or
        otherwise mistreat any person; Solicit passwords for any purpose, or
        personal identifying information for commercial or unlawful purposes
        from other users or disseminate another person’s personal information
        without his or her permission; Use another user’s account; Use our
        Services in relation to fraud, a pyramid scheme, or other similar
        practice; Disclose private or proprietary information that you do not
        have the right to disclose; Copy, modify, transmit, distribute, or
        create any derivative works from, any Member Content or Our Content, or
        any copyrighted material, images, trademarks, trade names, service
        marks, or other intellectual property, content or proprietary
        information accessible through our Services without Jammin’ Together’s
        prior written consent;; Use any robot, crawler, site search/retrieval
        application, proxy or other manual or automatic device, method or
        process to access, retrieve, index, “data mine,” or in any way reproduce
        or circumvent the navigational structure or presentation of our Services
        or its contents; Upload viruses or other malicious code or otherwise
        compromise the security of our Services; Forge headers or otherwise
        manipulate identifiers to disguise the origin of any information
        transmitted to or through our Services; “Frame” or “mirror” any part of
        our Services without Jammin’ Together’s prior written authorization; Use
        meta tags or code or other devices containing any reference to Jammin’
        Together or the platform (or any trademark, trade name, service mark,
        logo or slogan of Jammin’ Together) to direct any person to any other
        website for any purpose; Modify, adapt, sublicense, translate, sell,
        reverse engineer, decipher, decompile or otherwise disassemble any
        portion of our Services, or cause others to do so; Use or develop any
        third-party applications that interact with our Services or Member
        Content or information without our written consent; Use, access, or
        publish the Jammin’ Together application programming interface without
        our written consent; Probe, scan or test the vulnerability of our
        Services or any system or network; or Encourage, promote, or agree to
        engage in any activity that violates these Terms.
      </p>{" "}
      <p>
        {" "}
        If you are using the Services on behalf of a business or some other
        entity, you represent that you are authorized to bind that business or
        entity to these Terms and you agree to these Terms on behalf of that
        business or entity (and all references to “you” and “your” in these
        Terms will mean both you as the end user and that business or entity).{" "}
      </p>{" "}
      <h3>2. The Services</h3>{" "}
      <p>
        You acknowledge that your ability to obtain connections, logistics
        and/or booking services from third party providers through the use of
        the platform and services does not establish Jammin’ Together as a
        provider of music, gigs or booking services. You acknowledge that
        independent third party providers, including venues and musicians are
        not actual agents, apparent agents, ostensible agents, or employees of
        Jammin’ Together in any way. You also acknowledge that any safety
        related effort, feature, process, policy, standard or other effort
        undertaken by Jammin’ Together, in the interest of public safety
        (whether required by applicable regulations or not), is not an indicia
        of an employment, actual agency, apparent agency, or ostensible agency
        relationship with an independent third party venue or musician.{" "}
      </p>{" "}
      <p>
        {" "}
        Connection Services. Jammin’ Together makes it easier to connect local
        venues and local artists (the “Connection Services”) through the Jammin’
        Together Sites to User for the purpose of assisting User in booking gigs
        or attending shows at participating venues. Jammin’ Together connects
        the artists and the venues. Jammin’ Together is not responsible for any
        incorrect information on either the artist or the venue’s profile in
        regards to upcoming shows or if a show is cancelled. Jammin’ Together is
        also not responsible if a show is booked, but the artist does not show
        up for any reason.No-shows, cancellations, clerical errors, or any other
        reason that a show is no longer happening is due to the Artist or the
        Venue, not Jammin’ Together. Users are responsible for updating shows
        that will no longer be happening in order to best take care of any
        third-party supporters that desire to attend.{" "}
      </p>{" "}
      <h3> 3. Content </h3>{" "}
      <p>
        While using our Services, you will have access to: (i) content that you
        upload or provide while using our Services (“Your Content”); (ii)
        content that other users upload or provide while using our Services
        (“Member Content”); and (iii) content that Jammin’ Together provides on
        and through our Services (“Our Content”). In this agreement, “content”
        includes, without limitation, all text, images, video, audio, or other
        material on our Services, including information on users’ profiles and
        in direct messages between users.{" "}
      </p>{" "}
      <h3>3a. Your Content</h3>{" "}
      <p>
        {" "}
        You are solely responsible and liable for Your Content, and, therefore,
        you agree to indemnify, defend, release, and hold us harmless from any
        claims made in connection with Your Content.{" "}
      </p>{" "}
      <p>
        You represent and warrant to us that the information you provide to us
        or any other user is accurate, including any information submitted
        through other third-party sources (if applicable), and that you will
        update your account information as necessary to ensure its accuracy.{" "}
      </p>{" "}
      <p>
        The content included on your individual profile should be relevant to
        the intended use of our Services. You may not display any personal
        contact or banking information, whether in relation to you or any other
        person (for example, names, home addresses or postcodes, telephone
        numbers, email addresses, URLs, credit/debit card or other banking
        details). If you choose to reveal any personal information about
        yourself to other users, you do so at your own risk. We encourage you to
        use caution in disclosing any personal information online.{" "}
      </p>{" "}
      <p>
        Your individual profile will be visible to other people around the
        world, so be sure that you are comfortable sharing Your Content before
        you post. You acknowledge and agree that Your Content may be viewed by
        other users, and, notwithstanding these Terms, other users may share
        Your Content with third parties. By uploading Your Content, you
        represent and warrant to us that you have all necessary rights and
        licenses to do so and automatically grant us a license to use Your
        Content.{" "}
      </p>{" "}
      <p>
        {" "}
        You understand and agree that we may monitor or review Your Content, and
        we have the right to remove, delete, edit, limit, or block or prevent
        access to any of Your Content at any time in our sole discretion.
        Furthermore, you understand and agree that we have no obligation to
        display or review Your Content.{" "}
      </p>{" "}
      <h3>3b. Member Content</h3>{" "}
      <p>
        {" "}
        Other users will also share content on our Services. Member Content
        belongs to the user who posted the content and is stored on our servers
        and displayed at the direction of that user.{" "}
      </p>{" "}
      <p>
        {" "}
        You do not have any rights in relation to Member Content, and you may
        only use Member Content to the extent that your use is consistent with
        our Services’ purpose of allowing you to communicate with and meet one
        another. You may not copy the Member Content or use Member Content for
        commercial purposes, to spam, to harass, or to make unlawful threats. We
        reserve the right to terminate your account if you misuse Member
        Content.{" "}
      </p>{" "}
      <h3>3c. Our Content </h3>{" "}
      <p>
        {" "}
        Any other text, content, graphics, user interfaces, trademarks, logos,
        sounds, artwork, images, and other intellectual property appearing on
        our Services is owned, controlled or licensed by us and protected by
        copyright, trademark and other intellectual property law rights. All
        rights, title, and interest in and to Our Content remains with us at all
        times. We grant you a limited license to access and use Our Content and
        we reserve all other rights.{" "}
      </p>{" "}
      <p>
        {" "}
        Our Right to Use Your Content. We may use Your Content in a number of
        different ways, including by publicly displaying it, reformatting it,
        incorporating it into advertisements and other works, creating
        derivative works from it, promoting it, distributing it, and allowing
        others to do the same in connection with their own websites and media
        platforms (“Other Media”). As such, you hereby irrevocably grant us
        world-wide, perpetual, non-exclusive, royalty-free, assignable,
        sublicensable, transferable rights to use Your Content for any purpose.
        Please note that you also irrevocably grant the users of the Service and
        any Other Media the right to access Your Content in connection with
        their use of the Service and any Other Media. Finally, you irrevocably
        waive, and cause to be waived, against Jammin’ Together and its users
        any claims and assertions of moral rights or attribution with respect to
        Your Content. By “use” we mean use, copy, publicly perform and display,
        reproduce, distribute, modify, translate, remove, analyze,
        commercialize, and prepare derivative works of Your Content.
      </p>{" "}
      <p>
        {" "}
        Ownership. As between you and Jammin’ Together, you own Your Content. We
        own the Jammin’ Together Content, including but not limited to visual
        interfaces, interactive features, graphics, design, compilation
        (including, but not limited to, our selection, coordination,
        aggregation, and arrangement of User Content and other Service Content),
        computer code, products, software, aggregate star ratings, and all other
        elements and components of the Service excluding Your Content, User
        Content and Third Party Content. We also own the copyrights, trademarks,
        service marks, trade names, trade secrets, and other intellectual and
        proprietary rights throughout the world associated with the Jammin’
        Together Content and the Service, which are protected by copyright,
        trade dress, patent, trademark, and trade secret laws and all other
        applicable intellectual and proprietary rights and laws. As such, you
        may not sell, license, copy, publish, modify, reproduce, distribute,
        create derivative works or adaptations of, publicly display or in any
        way use or exploit any of the Jammin’ Together Content in whole or in
        part except as expressly authorized by us. Except as expressly and
        unambiguously provided herein, we do not grant you any express or
        implied rights, and all rights in and to the Service and the Jammin’
        Together Content are retained by us.{" "}
      </p>{" "}
      <p>
        Advertising. Jammin’ Together and its licensees may publicly display
        advertisements, paid content, and other information nearby or in
        association with Your Content. You are not entitled to any compensation
        for such advertisements. The manner, mode and extent of such advertising
        are subject to change without specific notice to you.{" "}
      </p>{" "}
      <p>
        {" "}
        Other. User Content (including any that may have been created by users
        employed or contracted by Jammin’ Together) does not necessarily reflect
        the opinion of Jammin’ Together. Except as required by law, we have no
        obligation to retain or provide you with copies of Your Content, and we
        do not guarantee any confidentiality with respect to Your Content. We
        reserve the right to remove, screen, edit, or reinstate User Content at
        our sole discretion for any reason or no reason, and without notice to
        you. For example, we may remove a review if we believe it violates our
        Content Guidelines.{" "}
      </p>{" "}
      <h3> 4. The Content of Others </h3>{" "}
      <p>
        {" "}
        Much of the content on our Services is produced by users, publishers,
        and other third parties. Whether that content is posted publicly or sent
        privately, the content is the sole responsibility of the user or entity
        that submitted it. Although Jammin’ Together reserves the right to
        review or remove all content that appears on the Services, we do not
        necessarily review all of it. So we cannot—and do not—guarantee that
        other users or the content they provide through the Services will comply
        with our Terms.{" "}
      </p>{" "}
      <h3>5. Respecting the Services and Jammin’ Together’s Rights</h3>
      <p>
        You must also respect Jammin’ Together’s rights and adhere to any other
        guidelines, support pages, or FAQ’s published by Jammin’ Together or our
        affiliates. That means, among other things, you may not do, attempt to
        do, enable, or encourage anyone else to do, any of the following: use
        branding, logos, icons, user interface elements, designs, photographs,
        videos, or any other materials Jammin’ Together makes available via the
        Services, except as explicitly allowed by these Terms or other brand
        guidelines published by Jammin’ Together or our affiliates; violate or
        infringe Jammin’ Together or our affiliates’ copyrights, trademarks, or
        other intellectual property rights; copy, modify, archive, download,
        upload, disclose, distribute, sell, lease, syndicate, broadcast,
        perform, display, make available, make derivatives of, or otherwise use
        the Services or the content on the Services, other than temporary files
        that are automatically cached by your web browser for display purposes,
        as otherwise expressly permitted in these Terms, as otherwise expressly
        permitted by us in writing, or as enabled by the Service’s intended
        functionality; create more than one account for yourself, create another
        account if we have already disabled your account, attempt to access the
        Services through unauthorized third-party applications, solicit login
        credentials from other users, or buy, sell, rent, or lease access to
        your account, a username, Jammin’ Together’s, or a friend link; reverse
        engineer, duplicate, decompile, disassemble, or decode the Services
        (including any underlying idea or algorithm), or otherwise extract the
        source code of the software of the Service; use any robot, spider,
        crawler, scraper, or other automated means or interface to access the
        Services or extract other users’ information; use or develop any
        third-party applications that interact with the Services or other users’
        content or information without our written consent; use the Services in
        a way that could interfere with, disrupt, negatively affect, or inhibit
        other users from fully enjoying the Services, or that could damage,
        disable, overburden, or impair the functioning of the Services; upload
        viruses or other malicious code or otherwise compromise, bypass, or
        circumvent the security of the Services; attempt to circumvent any
        content-filtering techniques we employ, or attempt to access areas or
        features of the Services that you are not authorized to access; probe,
        scan, or test the vulnerability of our Services or any system or
        network; violate any applicable law or regulation in connection with
        your access to or use of the Services; or access or use the Services in
        any way not expressly permitted by these Terms.
      </p>{" "}
      <h3>6. Respecting Others' Rights</h3>{" "}
      <p>
        {" "}
        Jammin’ Together respects the rights of others. And so should you. You
        therefore may not use the Services, or enable anyone else to use the
        Services, in a manner that violates or infringes someone else’s rights
        of publicity, privacy, copyright, trademark, or other intellectual
        property right. When you submit content to the Service, you agree and
        represent that you own that content, or that you have received all
        necessary permissions, clearances, and authorizations in order to submit
        it to the Service (including, if applicable, the right to make
        mechanical reproductions of the musical works embodied in any sound
        recordings, synchronize any compositions to any content, publicly
        perform any compositions or sound recordings, or any other applicable
        rights for any music not provided by Jammin’ Together that you include
        in your content) and grant the rights and licenses contained in these
        Terms for your content. You also agree that you will not use or attempt
        to use another user’s account except as permitted by Jammin’ Together or
        its affiliates.
      </p>{" "}
      <p>
        {" "}
        Jammin’ Together honors copyright laws, including the Digital Millennium
        Copyright Act and takes reasonable steps to expeditiously remove from
        our Services any infringing material that we become aware of. If Jammin’
        Together becomes aware that a user has repeatedly infringed copyrights,
        we will take reasonable steps within our power to terminate the user’s
        account. If you believe that anything on the Services infringes a
        copyright that you own or control, please report it or you may file a
        notice with our designated agent: support@jammintogether.com. If you
        file a notice with our Copyright Agent, it must comply with the
        requirements set forth at 17 U.S.C. § 512(c)(3). That means the notice
        must: contain the physical or electronic signature of a person
        authorized to act on behalf of the copyright owner; identify the
        copyrighted work claimed to have been infringed; identify the material
        that is claimed to be infringing or to be the subject of infringing
        activity and that is to be removed, or access to which is to be
        disabled, and information reasonably sufficient to let us locate the
        material; provide your contact information, including your address,
        telephone number, and an email address; provide a personal statement
        that you have a good-faith belief that the use of the material in the
        manner complained of is not authorized by the copyright owner, its
        agent, or the law; and provide a statement that the information in the
        notification is accurate and, under penalty of perjury, that you are
        authorized to act on behalf of the copyright owner.{" "}
      </p>{" "}
      <h3> 7. Safety </h3>{" "}
      <p>
        {" "}
        We try hard to keep our Services a safe place for all users. But we
        can’t guarantee it. That’s where you come in. By using the Services, you
        agree that you will at all times comply with these Terms and any other
        policies Jammin’ Together makes available in order to maintain the
        safety of the Services. If you fail to comply, we reserve the right to
        remove any offending content, terminate or limit the visibility of your
        account, and notify third parties—including law enforcement—and provide
        those third parties with information relating to your account. This step
        may be necessary to protect the safety of our users, and others, to
        investigate, remedy, and enforce potential Terms violations, and to
        detect and resolve any fraud or security concerns.{" "}
      </p>{" "}
      <p>
        {" "}
        We also care about your physical safety while using our Services. So do
        not use our Services in a way that would distract you from obeying
        traffic or safety laws. For example, never use the Services while
        driving. And never put yourself or others in harm’s way just to catch
        some live music or view a profile.{" "}
      </p>{" "}
      <h3> 8. No Criminal Background Or Identity Verification Checks </h3>{" "}
      <p>
        {" "}
        YOU UNDERSTAND THAT JAMMIN’ TOGETHER DOES NOT CONDUCT CRIMINAL
        BACKGROUND OR IDENTITY VERIFICATION CHECKS ON ITS USERS OR OTHERWISE
        INQUIRE INTO THE BACKGROUND OF ITS USERS. JAMMIN’ TOGETHER MAKES NO
        REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT, IDENTITY, INTENTIONS,
        LEGITIMACY, OR VERACITY OF USERS. JAMMIN’ TOGETHER RESERVES THE RIGHT TO
        CONDUCT—AND YOU AUTHORIZE JAMMIN’ TOGETHER TO CONDUCT—ANY CRIMINAL
        BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER
        SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS, AND YOU AGREE THAT
        ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT PURPOSE. IF THE COMPANY
        DECIDES TO CONDUCT ANY SCREENING THROUGH A CONSUMER REPORTING AGENCY,
        YOU HEREBY AUTHORIZE THE COMPANY TO OBTAIN AND USE A CONSUMER REPORT
        ABOUT YOU TO DETERMINE YOUR ELIGIBILITY UNDER THESE TERMS.{" "}
      </p>{" "}
      <p>
        {" "}
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. SEX
        OFFENDER SCREENINGS AND OTHER TOOLS DO NOT GUARANTEE YOUR SAFETY AND ARE
        NOT A SUBSTITUTE FOR FOLLOWING THE SAFETY TIPS AND OTHER SENSIBLE SAFETY
        PRECAUTIONS. ALWAYS USE YOUR BEST JUDGMENT AND TAKE APPROPRIATE SAFETY
        PRECAUTIONS WHEN COMMUNICATING WITH OR MEETING NEW PEOPLE.
        COMMUNICATIONS RECEIVED THROUGH THE SERVICE, INCLUDING AUTOMATIC
        NOTIFICATIONS SENT BY JAMMIN’ TOGETHER, MAY RESULT FROM USERS ENGAGING
        WITH THE SERVICE FOR IMPROPER PURPOSES, INCLUDING FRAUD, ABUSE,
        HARASSMENT, OR OTHER SUCH IMPROPER BEHAVIOR.
      </p>{" "}
      <p>
        {" "}
        Though Jammin’ Together strives to encourage a respectful user
        experience, it is not responsible for the conduct of any user on or off
        the Service. You agree to use caution in all interactions with other
        users, particularly if you decide to communicate off the Service or meet
        in person.{" "}
      </p>{" "}
      <h3> 9. Your Account </h3>{" "}
      <p>
        To use certain Services, you need to create an account. You agree to
        provide us with accurate, complete, and updated information for your
        account. You are responsible for any activity that occurs in your
        account. So it’s important that you keep your account secure. One way to
        do that is to select a strong password that you don’t use for any other
        account. If you think that someone has gained access to your account,
        please immediately reach out to us. Any software that we provide you may
        automatically download and install upgrades, updates, or other new
        features. You may be able to adjust these automatic downloads through
        your device’s settings. You agree not to create any account if we have
        previously removed or banned you or your account from any of our
        Services, unless we consent otherwise.{" "}
      </p>{" "}
      <h3>
        {" "}
        10. Purchases, Automatically Renewing Subscriptions, And Refunds{" "}
      </h3>{" "}
      <p>
        {" "}
        Jammin’ Together may offer products and services for purchase via credit
        card or other payment processors on the Website or inside the App
        (“Internal Purchases”). If you purchase a subscription, it will
        automatically renew until you cancel, in accordance with the terms
        disclosed to you at the time of purchase, as further described below. If
        you cancel your subscription, you will continue to have access to your
        subscription benefits until the end of your subscription period, at
        which point it will expire. Because our Services may be utilized without
        a subscription, canceling your subscription does not remove your profile
        from our Services. All purchases are final and nonrefundable, and there
        are no refunds or credits for partially used periods, except if the laws
        applicable in your jurisdiction provide for refunds.{" "}
      </p>{" "}
      <h3> 11. Data Charges and Mobile Phones </h3>{" "}
      <p>
        {" "}
        You are responsible for any mobile charges that you may incur for using
        our Services, including text-messaging (such as SMS, MMS, or future such
        protocols or technologies) and data charges. If you’re unsure what those
        charges may be, you should ask your service provider before using the
        Services. By providing us with your mobile phone number, you agree to
        receive SMS messages from Jammin’ Together related to the Services,
        including about promotions, your account, messages from other profiles,
        and your relationship with Jammin’ Together. These SMS messages may be
        made to your mobile phone number even if your mobile phone number is
        registered on any state or federal Do Not Call list, or international
        equivalent. If you change or deactivate the mobile phone number that you
        used to create an account, you must update your account information
        within 72 hours to prevent us from sending messages intended for you to
        someone else.{" "}
      </p>{" "}
      <h3> 12. Network Access and Devices</h3>{" "}
      <p>
        {" "}
        You are responsible for obtaining the data network access necessary to
        use the Services. Your mobile network's data and messaging rates and
        fees may apply if you access or use the Services from your device. You
        are responsible for acquiring and updating compatible hardware or
        devices necessary to access and use the Services and Applications and
        any updates thereto. Jammin’ Together does not guarantee that the
        Services, or any portion thereof, will function on any particular
        hardware or devices. In addition, the Services may be subject to
        malfunctions and delays inherent in the use of the Internet and
        electronic communications.
      </p>{" "}
      <h3> 13. Third-Party Services </h3>{" "}
      <p>
        {" "}
        Certain Services may display, include or make available content, data,
        information, applications, features or materials from third parties
        (“Third-Party Materials”), or provide links to certain third-party
        websites. If you use any Third-Party Materials made available through
        our Services (including Services we jointly offer with the third party),
        each party’s terms will govern the respective party’s relationship with
        you. Neither Jammin’ Together nor our affiliates are responsible or
        liable for a third party’s terms or actions taken under the third
        party’s terms. Further, by using the Services, you acknowledge and agree
        that Jammin’ Together is not responsible for examining or evaluating the
        content, accuracy, completeness, availability, timeliness, validity,
        copyright compliance, legality, decency, quality or any other aspect of
        such Third-Party Materials or websites. We do not warrant or endorse and
        do not assume and will not have any liability or responsibility to you
        or any other person for any third-party services, Third-Party Materials
        or third-party websites, or for any other materials, products, or
        services of third parties. Third-Party Materials and links to other
        websites are provided solely as a convenience to you.{" "}
      </p>{" "}
      <h3> 14. Third-Party Websites, Applications and Services </h3>{" "}
      <p>
        {" "}
        The Services may contain hypertext links to websites and applications
        operated by parties other than Jammin’ Together. Such hypertext links
        are provided for User’s reference only, and Jammin’ Together does not
        control such websites and is not responsible for their content. Jammin’
        Together’s inclusion of any hypertext links to such websites or
        applications does not imply any endorsement of the material on such
        websites or applications or any association with their operators.
        Jammin’ Together assumes no liability whatsoever for any such
        third-party websites, applications or any content, features, products,
        or services made available through such third-party websites or
        applications.{" "}
      </p>{" "}
      <h3> 15. Modifying the Services and Termination </h3>{" "}
      <p>
        {" "}
        We’re relentlessly improving our Services and creating new ones all the
        time. That means we may add or remove features, products, or
        functionalities, and we may also suspend or stop the Services
        altogether. We may take any of these actions at any time for any reason,
        and when we do, we may not provide you with any notice beforehand.{" "}
      </p>{" "}
      <p>
        {" "}
        While we hope you remain a lifelong customer or user, you can terminate
        these Terms at any time and for any reason by deleting your Jammin’
        Together account (or, in some cases, the account associated with the
        applicable part of the Services you are using).{" "}
      </p>{" "}
      <p>
        {" "}
        We may terminate or temporarily suspend your access to the Services if
        you fail to comply with these Terms or the law, for any reason outside
        of our control, or for any reason, and without advanced notice. That
        means that we may terminate these Terms, stop providing you with all or
        any part of the Services, or impose new or additional limits on your
        ability to use our Services. And while we’ll try to give you reasonable
        notice beforehand, we can’t guarantee that notice will be possible in
        all circumstances. For example, we may deactivate your account due to
        prolonged inactivity, and we may reclaim your username at any time for
        any reason.{" "}
      </p>{" "}
      <p>
        {" "}
        Regardless of who terminates these Terms, both you and Jammin’ Together
        continue to be bound by the Terms.
      </p>{" "}
      <h3> 16. Indemnity</h3>{" "}
      <p>
        {" "}
        You agree, to the extent permitted by law, to indemnify, defend, and
        hold harmless Jammin’ Together, our affiliates, directors, officers,
        stockholders, employees, licensors, and agents from and against any and
        all complaints, charges, claims, damages, losses, costs, liabilities,
        and expenses (including attorneys’ fees) due to, arising out of, or
        relating in any way to: (a) your access to or use of the Services, or
        any products or services provided by a third party in connection with
        the Services, even if recommended, made available, or approved by
        Jammin’ Together; (b) your content, including infringement claims
        related to your content; (c) your breach of these Terms or any
        applicable law or regulation; or (d) your negligence or willful
        misconduct.{" "}
      </p>{" "}
      <h3> 17. Disclaimers </h3>{" "}
      <p>
        {" "}
        We try to keep the Services up and running and free of annoyances. But
        we make no promises that we will succeed.{" "}
      </p>{" "}
      <p>
        {" "}
        THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE EXTENT
        PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
        NON-INFRINGEMENT. IN ADDITION, WHILE WE ATTEMPT TO PROVIDE A GOOD USER
        EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (A) THE SERVICES WILL
        ALWAYS BE SECURE, ERROR-FREE, OR TIMELY; (B) THE SERVICES WILL ALWAYS
        FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY
        CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE
        SERVICES WILL BE TIMELY OR ACCURATE.{" "}
      </p>{" "}
      <p>
        {" "}
        NEITHER WE NOR OUR AFFILIATES TAKE RESPONSIBILITY OR ASSUME LIABILITY
        FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES,
        UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES.
        YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT
        BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF
        WHICH WE OR OUR AFFILIATES WILL BE RESPONSIBLE FOR.{" "}
      </p>
      <h3>18. Limitation of Liability</h3>{" "}
      <p>
        {" "}
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE AND OUR MANAGING MEMBERS,
        SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AGENTS, AND SUPPLIERS
        WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
        PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
        WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
        GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO
        OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) THE CONDUCT OR
        CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR
        (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF WE
        HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL
        OUR AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED
        THE GREATER OF $100 USD OR THE AMOUNT YOU PAID US IN THE 12 MONTHS
        PRECEDING THE DATE OF THE ACTIVITY GIVING RISE TO THE CLAIM.{" "}
      </p>{" "}
      <h3> 19. Arbitration, Class-Action Waiver, and Jury Waiver </h3>{" "}
      <p>
        {" "}
        PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY PROVIDE THAT
        YOU AND JAMMIN’ TOGETHER AGREE TO RESOLVE ALL DISPUTES BETWEEN US
        THROUGH BINDING INDIVIDUAL ARBITRATION.
      </p>{" "}
      <p>
        {" "}
        a. Applicability of Arbitration Agreement. In this Section 19 (the
        “Arbitration Agreement”), You and Jammin’ Together agree that all claims
        and disputes (whether contract, tort, or otherwise), including all
        statutory claims and disputes, arising out of or relating to these Terms
        or the use of the Services that cannot be resolved in small claims court
        will be resolved by binding arbitration on an individual basis, except
        that you and Jammin’ Together are not required to arbitrate any dispute
        in which either party seeks equitable relief for the alleged unlawful
        use of copyrights, trademarks, trade names, logos, trade secrets, or
        patents. To be clear: the phrase “all claims and disputes” also includes
        claims and disputes that arose between us before the effective date of
        these Terms. In addition, all disputes concerning the arbitrability of a
        claim (including disputes about the scope, applicability,
        enforceability, revocability, or validity of the Arbitration Agreement)
        shall be decided by the arbitrator, except as expressly provided below.{" "}
      </p>{" "}
      <p>
        {" "}
        b. Arbitration Rules. The Federal Arbitration Act governs the
        interpretation and enforcement of this dispute-resolution provision.
        Arbitration will be initiated through the American Arbitration
        Association (“AAA”) and will be governed by the AAA Consumer Arbitration
        Rules or by calling the AAA at 1-800-778-7879. If the AAA is not
        available to arbitrate, the parties will select an alternative arbitral
        forum. The rules of the arbitral forum will govern all aspects of this
        arbitration, except to the extent those rules conflict with these Terms.
        The arbitration will be conducted by a single neutral arbitrator. Any
        claims or disputes where the total amount sought is less than $10,000
        USD may be resolved through binding non-appearance-based arbitration, at
        the option of the party seeking relief. For claims or disputes where the
        total amount sought is $10,000 USD or more, the right to a hearing will
        be determined by the arbitral forum’s rules. Any judgment on the award
        rendered by the arbitrator may be entered in any court of competent
        jurisdiction.{" "}
      </p>{" "}
      <p>
        {" "}
        c. Additional Rules for Non-appearance Arbitration. If non-appearance
        arbitration is elected, the arbitration will be conducted by telephone,
        online, written submissions, or any combination of the three; the
        specific manner will be chosen by the party initiating the arbitration.
        The arbitration will not involve any personal appearance by the parties
        or witnesses unless the parties mutually agree otherwise.
      </p>{" "}
      <p>
        {" "}
        d. Fees. Pursuant to the AAA Consumer Arbitration Rules, other than the
        filing fee to be paid by you, all other costs of the arbitration will be
        borne by Jammin’ Together, including any remaining Case Management Fee
        and all professional fees for the arbitrator’s services. If the
        arbitration filing fee exceeds the filing fee to bring a similar claim
        in the federal district court where you reside, Jammin’ Together will
        reimburse you for the difference between the arbitration filing fee and
        the filing fee that would have applied in the federal district court
        where you reside. If Jammin’ Together is the party initiating an
        arbitration against you, Jammin’ Together will pay all costs associated
        with the arbitration, including the entire filing fee.
      </p>{" "}
      <p>
        {" "}
        e. Authority of the Arbitrator. The arbitrator will decide the
        jurisdiction of the arbitrator and the rights and liabilities, if any,
        of you and Jammin’ Together. The dispute will not be consolidated with
        any other matters or joined with any other cases or parties. The
        arbitrator will have the authority to grant motions dispositive of all
        or part of any claim or dispute. The arbitrator will have the authority
        to award monetary damages and to grant any non-monetary remedy or relief
        available to an individual under law, the arbitral forum’s rules, and
        the Terms. The arbitrator will issue a written award and statement of
        decision describing the essential findings and conclusions on which the
        award is based, including the calculation of any damages awarded. The
        arbitrator has the same authority to award relief on an individual basis
        that a judge in a court of law would have. The award of the arbitrator
        is final and binding upon you and Jammin’ Together.{" "}
      </p>{" "}
      <p>
        {" "}
        f. Waiver of Jury Trial. YOU AND JAMMIN’ TOGETHER WAIVE ANY
        CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
        FRONT OF A JUDGE OR A JURY. You and Jammin’ Together are instead
        electing to have claims and disputes resolved by arbitration.
        Arbitration procedures are typically more limited, more efficient, and
        less costly than rules applicable in court and are subject to very
        limited review by a court. In any litigation between you and Jammin’
        Together over whether to vacate or enforce an arbitration award, YOU AND
        JAMMIN’ TOGETHER WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to
        have the dispute be resolved by a judge.
      </p>{" "}
      <p>
        {" "}
        g. Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES
        WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR
        LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF
        MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY
        OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
        Notwithstanding any other provision of this Agreement, the Arbitration
        Agreement or the AAA Rules, disputes regarding the interpretation,
        applicability, or enforceability of this waiver may be resolved only by
        a court and not by an arbitrator. If this waiver of class or
        consolidated actions is deemed invalid or unenforceable, neither you nor
        we are entitled to arbitration; instead all claims and disputes will be
        resolved in a court as set forth in Section 20.
      </p>{" "}
      <p>
        {" "}
        h. Right to Waive. Any rights and limitations set forth in this
        Arbitration Agreement may be waived by the party against whom the claim
        is asserted. Such waiver will not waive or affect any other portion of
        this Arbitration Agreement.
      </p>{" "}
      <p>
        {" "}
        i. Opt-out. You may opt out of this Arbitration Agreement. If you do so,
        neither you nor Jammin’ Together can force the other to arbitrate. To
        opt out, you must notify Jammin’ Together in writing no later than 30
        days after first becoming subject to this Arbitration Agreement. Your
        notice must include your name and address, your Jammin’ Together
        username and the email address you used to set up your Jammin’ Together
        account (if you have one), and an unequivocal statement that you want to
        opt out of this Arbitration Agreement. You must either mail your opt-out
        notice to this address: Jammin’ Together LLC, 223 Sumner Rd, Annapolis,
        MD, 21401, or email the opt-out notice to support@jammintogether.com.{" "}
      </p>{" "}
      <p>
        {" "}
        j. Small Claims Court. Notwithstanding the foregoing, either you or
        Jammin’ Together may bring an individual action in small claims court.
        k. Arbitration Agreement Survival. This Arbitration Agreement will
        survive the termination of your relationship with Jammin’ Together.
      </p>{" "}
      <h3>20. Exclusive Venue </h3>{" "}
      <p>
        {" "}
        To the extent that these Terms allow you or Jammin’ Together to initiate
        litigation in a court, both you and Jammin’ Together agree that all
        claims and disputes (whether contract, tort, or otherwise), including
        statutory claims and disputes, arising out of or relating to the Terms
        or the use of the Services will be litigated exclusively in the United
        States District Court for the District of Maryland. If, however, that
        court would lack original jurisdiction over the litigation, then all
        such claims and disputes will be litigated exclusively in the Circuit
        Court, County of Anne Arundel. You and Jammin’ Together consent to the
        personal jurisdiction of both courts.{" "}
      </p>{" "}
      <h3>21. Choice of Law </h3>{" "}
      <p>
        {" "}
        Except to the extent they are preempted by U.S. federal law, the laws of
        Maryland, other than its conflict-of-laws principles, govern these Terms
        and any claims and disputes (whether contract, tort, or otherwise)
        arising out of or relating to these Terms or their subject matter.{" "}
      </p>{" "}
      <h3> 22. Severability </h3>{" "}
      <p>
        {" "}
        If any provision of these Terms is found unenforceable, then that
        provision will be severed from these Terms and not affect the validity
        and enforceability of any remaining provisions.{" "}
      </p>{" "}
      <h3>23. Final Terms </h3>{" "}
      <p>
        {" "}
        These Terms, including the additional terms referenced in Section 4,
        make up the entire agreement between you and Jammin’ Together, and
        supersede any prior agreements. These Terms do not create or confer any
        third-party beneficiary rights. If we do not enforce a provision in
        these Terms, it will not be considered a waiver. We reserve the right to
        transfer our rights under these Terms and provide the Services using
        another entity, provided that entity upholds these Terms. You may not
        transfer any of your rights or obligations under these Terms without our
        consent. We reserve all rights not expressly granted to you.{" "}
      </p>{" "}
      <h3> 24. Our Ask </h3>{" "}
      <p>
        Be a good person and treat others how you want to be treated. We
        contribute to human progress by empowering people to express themselves,
        live in the moment, learn about the world, and have fun together. We
        created Jammin’ Together to support local music communities and bring
        more fun, joy, and happiness to the world. Please support our mission by
        encouraging the broadest range of self-expression while making sure all
        users can use our services safely every day. We ask you to be a good
        person and have this apply to all content on Jammin’ Together — and to
        all users equally. We've done our best below to set out specific rules
        of the road for what content we allow on Jammin’ Together. But it's hard
        to anticipate every situation that might come up. The key is the spirit
        of these rules: we want Jammin’ Together to be a safe and positive
        experience for everyone. We reserve the right to decide, in our sole
        discretion, what content violates that spirit and will not be permitted
        on the platform.{" "}
      </p>{" "}
      <h3>Sexually Explicit Content </h3>{" "}
      <p>
        We prohibit accounts that promote or distribute pornographic content. We
        report child sexual exploitation to authorities. Never post, save, or
        send nude or sexually explicit content involving anyone under the age of
        18 — even of yourself. Never ask a minor to send explicit imagery or
        chats.
      </p>{" "}
      <h3> Harassment & Bullying</h3>{" "}
      <p>
        {" "}
        We prohibit bullying or harassment of any kind. Don’t send a message
        with the intention of making someone feel bad. If someone blocks you,
        don’t try to contact them from another account. If someone is in your
        video and asks you to remove it, please do!
      </p>{" "}
      <h3>Threats, Violence & Harm</h3>{" "}
      <p>
        Encouraging violence or dangerous behavior is prohibited — never
        threaten to harm a person, a group of people, or someone’s property.
        Posts of gratuitous or graphic violence are not allowed. We don’t allow
        the glorification of self-harm, including the promotion of self-injury
        or eating disorders.
      </p>{" "}
      <h3> Impersonation, Deceptive Practices & False Information</h3>{" "}
      <p>
        {" "}
        Pretending to be someone you’re not — this includes your friends,
        celebrities, brands, or other organizations — or attempting to deceive
        people about who you are is not allowed. If you are a cover band, then
        simply admit it. We prohibit spam and other deceptive practices,
        including manipulating content for misleading purposes or to imitate
        Jammin’ Together content formats. We prohibit spreading false
        information that causes harm or is malicious.{" "}
      </p>{" "}
      <h3> Illegal Content</h3>{" "}
      <p>
        Don’t use Jammin’ Together for any illegal activities — including to buy
        or sell illegal drugs, contraband, counterfeit goods, or illegal
        weapons. We prohibit the promotion and use of certain regulated goods,
        as well as the depiction or promotion of criminal activities.{" "}
      </p>{" "}
      <h3> Terrorism, Hate Groups, and Hate Speech </h3>{" "}
      <p>
        {" "}
        Terrorist organizations and hate groups are prohibited from using our
        platform and we have no tolerance for content that advocates or advances
        violent extremism or terrorism. Hate speech or content that demeans,
        defames, or promotes discrimination or violence on the basis of race,
        color, caste, ethnicity, national origin, religion, sexual orientation,
        gender identity, disability, or veteran status, immigration status,
        socio-economic status, age, weight or pregnancy status is prohibited.{" "}
      </p>{" "}
      <p>
        {" "}
        Please remember that you can always file a report. We review these
        reports to determine whether there is a violation of these Terms and any
        action needs to be taken. If you go against Our Ask, we may remove the
        offending content, terminate or limit the visibility of your account,
        and/or notify law enforcement. If your account is terminated for
        violating our Terms of Service or these Guidelines, you may not use
        Jammin’ Together again. Please take Our Ask seriously and honor them in
        the spirit in which they are intended.
      </p>
    </div>
  );
};

export default Terms;
