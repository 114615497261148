import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../helpers/firebase";
import "../styles/reset.css";

function PassResetPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleReset = async () => {
    if (!email) {
      setError("Please enter an email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError(null);
      setSuccess(true);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("We couldn't find a user with that email.");
      } else if (error.code === "auth/invalid-email") {
        setError("Please enter a valid email address.");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="reset-page">
      {success ? (
        <h1>Check your email for a password reset link.</h1>
      ) : (
        <>
          <h1>Reset Password</h1>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleReset}>Reset Password</button>
          {error && <p>{error}</p>}{" "}
        </>
      )}
    </div>
  );
}

export default PassResetPage;
