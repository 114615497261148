import { loadStripe, confirmCardPayment } from "@stripe/stripe-js";
import { getEvent } from "./firestore";

export const stripePromise = loadStripe(
  "pk_test_51O4srXDCHNXaksOuAgPg7RNGPvUAR991E5ZBonqa1D78eqGsg8C2HOG0rB0tQ7sRGroY7bw51JZleTNPomLT6Vxy00VVpH74eb"
);

export const makePayment = async (cardElement, payment) => {
  const response = await fetch("http://localhost:3001/create-subscription", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ paymentID: payment }),
  });
  const session = await response.json();

  const result = await stripePromise.then((stripe) =>
    stripe.confirmCardPayment(session.client_secret, {
      payment_method: {
        card: cardElement,
      },
    })
  );

  return result;
};

export const calculateFeaturePrice = async (eventID) => {
  const event = await getEvent(eventID);
  const eventDate = new Date(event.date.seconds * 1000);
  const now = new Date();
  const differenceInDays = Math.floor(
    (eventDate - now) / (1000 * 60 * 60 * 24)
  );
  console.log(differenceInDays);
  return differenceInDays * 5;
};

export const fetchSecret = async (payment) => {
  console.log(payment);
  const response = await fetch(
    "http://localhost:3001/create-checkout-session",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paymentID: payment }),
    }
  );
  const data = await response.json();
  console.log(data);
  return data;
};
