import React from "react";
import "../styles/terms.css";

const PrivacyPage = () => {
  return (
    <div className="terms">
      <h1>Privacy Policy</h1>
      <p>
        {" "}
        <p>Effective: December 13, 2021</p>
      </p>{" "}
      <p>
        {" "}
        Jammin’ Together LLC is a Saas company. Our products and services
        provide connections between artists and venues and the ability to
        promote their upcoming shows.
      </p>{" "}
      <p>
        When you use these services, you’ll share some information with us. So
        we want to be upfront about the information we collect, how we use it,
        whom we share it with, and the controls we give you to access, update,
        and delete your information.
      </p>{" "}
      <p>
        That’s why we’ve written this Privacy Policy. And it’s why we’ve tried
        to write it in a way that’s easy to understand for all our users and
        blissfully free of the legalese that often clouds these documents. Of
        course, if you still have questions about anything in our Privacy
        Policy, just contact us.
      </p>{" "}
      <h3>Information We Collect</h3>{" "}
      <p>There are three basic categories of information we collect:</p>{" "}
      <p>Information you provide.</p>{" "}
      <p>Information we get when you use our services.</p>{" "}
      <p>Information we get from third parties.</p>{" "}
      <p>Here’s a little more detail on each of these categories.</p>{" "}
      <h3>Information You Provide</h3>{" "}
      <p>
        When you interact with our services, we collect information that you
        provide to us. For example, many of our services require you to set up
        an account, so we may need to collect a few important details about you,
        such as your name, username, password, email address, phone number, and
        location. We may also ask you to provide us with some additional
        information that will be publicly visible on our services, such as a
        profile picture, demo video, or other information used to promote you
        and your talents. Some services may require you to provide us with a
        debit or credit card number and its associated account information.
      </p>{" "}
      <p>
        Of course, you’ll also provide us whatever information you send through
        our services, such as messages and social media connections. Keep in
        mind that the users who view your profile, messages, and any other
        content can always save that content or copy it outside the app. So, the
        same common sense that applies to the internet at large applies to our
        services as well: Don’t send messages or share content that you wouldn’t
        want someone to save or share. When you contact customer support or
        communicate with us in any other way, we’ll collect whatever information
        you volunteer or that we need to resolve your question.
      </p>{" "}
      <h3>Information We Get When You Use Our Services</h3>{" "}
      <p>
        When you use our services, we collect information about which of those
        services you’ve used and how you’ve used them. We might know, for
        instance, that you viewed a particular profile, saw a specific ad for a
        certain period of time, and sent a few messages. Here’s a fuller
        explanation of the types of information we collect when you use our
        services:
      </p>{" "}
      <p>
        Usage Information. We collect information about your activity through
        our services.
      </p>{" "}
      <p>
        Content Information. We collect content you create on our services and
        information about the content you create or provide, such as if the
        recipient has viewed the content and the metadata that is provided with
        the content.
      </p>{" "}
      <p>
        Location Information. When you use our services we may collect
        information about your location. With your permission, we may also
        collect information about your precise location using methods that
        include GPS, wireless networks, cell towers, Wi-Fi access points, and
        other sensors, such as gyroscopes, accelerometers, and compasses.
      </p>{" "}
      <p>
        Information Collected by Cookies and Other Technologies. Like most
        online services and mobile applications, we may use cookies and other
        technologies, such as web beacons, web storage, and unique advertising
        identifiers, to collect information about your activity, browser, and
        device. We may also use these technologies to collect information when
        you interact with services we offer through one of our partners, such as
        advertising and commerce features. For example, we may use information
        collected on other websites to show you more relevant ads. Most web
        browsers are set to accept cookies by default. If you prefer, you can
        usually remove or reject browser cookies through the settings on your
        browser or device. Keep in mind, though, that removing or rejecting
        cookies could affect the availability and functionality of our services.{" "}
      </p>{" "}
      <p>
        Log Information. We also collect log information when you use our
        website, such as:
      </p>{" "}
      <p>details about how you’ve used our services;</p>{" "}
      <p>device information, such as your web browser type and language;</p>{" "}
      <p>access times;</p> <p>pages viewed;</p> <p>IP address;</p>{" "}
      <p>
        identifiers associated with cookies or other technologies that may
        uniquely identify your device or browser; and
      </p>{" "}
      <p>pages you visited before or after navigating to our website.</p>{" "}
      <h3>Information We Collect from Third Parties</h3>{" "}
      <p>
        We may collect information about you from other users, our affiliates,
        and third parties. Here are a few examples:
      </p>{" "}
      <p>
        If you link your Jammin’ Together account to another service, we may
        receive information from the other service, like how you use that
        service.
      </p>{" "}
      <p>
        Advertisers, app developers, publishers, and other third parties may
        share information with us as well. We may use this information, among
        other ways, to help target or measure the performance of ads. You can
        learn more about our use of this kind of third-party data in our Support
        Center.
      </p>{" "}
      <h3>How We Use Information</h3>{" "}
      <p>
        What do we do with the information we collect? The short answer is:
        Provide you with an amazing set of products and services that we
        relentlessly improve. Here are the ways we do that:
      </p>{" "}
      <p>
        develop, operate, improve, deliver, maintain, and protect our products
        and services.
      </p>{" "}
      <p>
        send you communications, including by email. For example, we may use
        email to respond to support inquiries or to share information about our
        products, services, and promotional offers that we think may interest
        you.
      </p>{" "}
      <p>monitor and analyze trends and usage.</p>{" "}
      <p>
        personalize our services by, among other things, suggesting profiles,
        profile information, or helping you connect with other profiles in the
        Jammin’ Together community, affiliate and third-party apps and services,
        or customizing the content we show you, including ads.
      </p>{" "}
      <p>
        provide and improve our advertising services, ad targeting, and ad
        measurement, including through the use of your precise location
        information (again, if you’ve given us permission to collect that
        information), both on and off our services. We may also store
        information about your use of third-party apps and websites on your
        device to do this.
      </p>{" "}
      <p>enhance the safety and security of our products and services.</p>{" "}
      <p>
        verify your identity and prevent fraud or other unauthorized or illegal
        activity.
      </p>{" "}
      <p>
        use information we’ve collected from cookies and other technology to
        enhance our services and your experience with them.
      </p>{" "}
      <p>
        enforce, investigate, and report conduct violating our Terms of Service
        and other usage policies, respond to requests from law enforcement, and
        comply with legal requirements.
      </p>{" "}
      <h3>How We Share Information</h3>{" "}
      <p>We may share information about you in the following ways:</p>{" "}
      <p>
        With other Jammin’ Together user. We may share the following information
        with other Jammin’ Together users:
      </p>{" "}
      <p>
        information about you, such as your artist or venue name, name, and
        other profile information.
      </p>{" "}
      <p>
        information about how you have interacted with our services, such as
        your profile interactions, the profiles you have liked or favorited, and
        other information that will help Jammin’ Together users understand your
        connections with others using our services.
      </p>{" "}
      <p>
        information about your device, such as the operating system and device
        type, to help you receive content in the optimal viewing format.
      </p>{" "}
      <p>any additional information you have directed us to share.</p>{" "}
      <p>
        With all Jammin’ Together users, our business partners, and the general
        public. We may share the following information with all Jammin’ Together
        users as well as with our business partners and the general public:
      </p>{" "}
      <p>
        public information like your name, profile media, social media links,
        and public profile.
      </p>{" "}
      <p>
        With our affiliates. We may share information with entities within
        Jammin’ Together
      </p>{" "}
      <p>
        With third parties. We may share information with third parties in the
        following ways:
      </p>{" "}
      <p>
        We may share information about you with service providers who perform
        services on our behalf, including to facilitate payments and measure and
        optimize the performance of ads and deliver more relevant ads, including
        on third-party websites and apps.
      </p>{" "}
      <p>
        We may share information about you with business partners that provide
        services and functionality on our services.
      </p>{" "}
      <p>
        We may share information about you, such as device and usage
        information, to help us and others prevent fraud.
      </p>{" "}
      <p>
        We may share information about you for legal, safety, and security
        reasons. We may share information about you if we reasonably believe
        that disclosing the information is needed to:
      </p>{" "}
      <p>
        comply with any valid legal process, governmental request, or applicable
        law, rule, or regulation.
      </p>{" "}
      <p>
        investigate, remedy, or enforce potential Terms of Service and Community
        Guidelines violations.
      </p>{" "}
      <p>
        protect the rights, property, or safety of us, our users, or others.
      </p>{" "}
      <p>detect and resolve any fraud or security concerns.</p>{" "}
      <p>
        We may share information about you as part of a merger or acquisition.
        If Jammin’ Together. gets involved in a merger, asset sale, financing,
        liquidation or bankruptcy, or acquisition of all or some portion of our
        business to another company, we may share your information with that
        company before and after the transaction closes.
      </p>{" "}
      <p>
        Non-personal information. We may also share with third parties that
        provide services to us or perform business purposes for us aggregated,
        non-personally identifiable, or de-identified information.
      </p>{" "}
      <h3>Third-Party Content and Integrations</h3>{" "}
      <p>
        Our services may contain third-party content and integrations.Through
        these integrations, you may be providing information to the third party
        as well as to Jammin’ Together. We are not responsible for how those
        third parties collect or use your information. As always, we encourage
        you to review the privacy policies of every third-party service that you
        visit or use, including those third parties you interact with through
        our services.
      </p>{" "}
      <h3>How Long We Keep Your Information</h3>{" "}
      <p>
        Jammin’ Together lets you connect with others to enhance the live music
        community and scene around you. On our end, that means most messages
        sent in Jammin’ Together will be stored by default from our servers
        until we detect they’ve been opened by all recipients or have expired or
        that we no longer need them to collect data in order to improve or
        enhance our services.{" "}
      </p>{" "}
      <p>We store other information for longer periods of time. For example:</p>{" "}
      <p>
        We store your basic account information until you ask us to delete them.
      </p>{" "}
      <p>
        We store location information for different lengths of time based on how
        precise it is and which services you use.
      </p>{" "}
      <p>
        If you ever decide to stop using Jammin’ Together, you can just ask us
        to delete your account. We’ll also delete most of the information we’ve
        collected about you after you’ve been inactive for a while!
      </p>{" "}
      <p>
        Keep in mind that, while our systems are designed to carry out our
        deletion practices automatically, we cannot promise that deletion will
        occur within a specific timeframe. There may be legal requirements to
        store your data and we may need to suspend those deletion practices if
        we receive valid legal process asking us to preserve content, if we
        receive reports of abuse or other Terms of Service violations, or if
        your account or content created by you is flagged by others or our
        systems for abuse or other Terms of Service violations. Finally, we may
        also retain certain information in backup for a limited period of time
        or as required by law.
      </p>{" "}
      <h3>Control Over Your Information</h3>{" "}
      <p>
        We want you to be in control of your information, so we provide you with
        the following tools.
      </p>{" "}
      <p>
        Access, Correction, and Portability. You can access and edit most of
        your basic account information right in our website. Because your
        privacy is important to us, we will ask you to verify your identity or
        provide additional information before we let you access or update your
        personal information. We may also reject your request to access or
        update your personal information for a number of reasons, including, for
        example, if the request risks the privacy of other users or is unlawful.
      </p>{" "}
      <p>
        Revoking permissions. In most cases, if you let us use your information,
        you can simply revoke your permission by changing the settings on the
        website or on your device if your device offers those options. Of
        course, if you do that, certain services may lose full functionality.
      </p>{" "}
      <p>
        Deletion. While we hope you’ll remain a lifelong member of Jammin’
        Together, if for some reason you ever want to delete your account, just
        reach out to learn how. You can also delete some information in the app,
        like photos you’ve uploaded.
      </p>{" "}
      <p>
        Advertising Preferences. We try to show you ads that we think will be
        relevant to your interests. If you would like to modify the information
        we and our advertising partners use to select these ads, you can do so
        on the website and through your device preferences.{" "}
      </p>{" "}
      <p>
        Tracking. If you opt out of tracking on devices running iOS 14.5 or more
        recent versions, we will not link identifiable information from
        third-party apps and websites with identifiable information from Jammin’
        Together for advertising purposes, except on your device.{" "}
      </p>{" "}
      <h3>International Data Transfers</h3>{" "}
      <p>
        We may collect your personal information from, transfer it to, and store
        and process it in the United States and other countries outside of where
        you live. Whenever we share information outside of where you live, when
        we are legally required to do so, we make sure an adequate transfer
        mechanism is in place. We also make sure any third parties we share
        information with have an adequate transfer mechanism in place, as well.
      </p>{" "}
      <h3>Children</h3>{" "}
      <p>
        Our services are not intended for — and we don’t direct them to — anyone
        under 18. And that’s why we do not knowingly collect personal
        information from anyone under 18.{" "}
      </p>{" "}
      <h3>Revisions to the Privacy Policy</h3>{" "}
      <p>
        We may change this Privacy Policy from time to time. But when we do,
        we’ll let you know one way or another. Sometimes, we’ll let you know by
        revising the date at the top of the Privacy Policy that’s available on
        our website and mobile application. Other times, we may provide you with
        additional notice (such as adding a statement to our websites’ homepages
        or providing you with an in-app notification).
      </p>{" "}
    </div>
  );
};
export default PrivacyPage;
