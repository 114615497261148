import React, { useState, useEffect } from "react";
import "../styles/search.css";
import DatePicker from "react-datepicker";
import { autofillVenues } from "../helpers/firestore";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      location: "",
      genre: "",
      venues: [],
      genres: [],
    };
    this.genres = [
      "Rock",
      "Jazz",
      "Pop",
      "Blues",
      "Modern",
      "Country",
      "Folk",
      "R&B",
      "Metal",
      "Indie",
      "Soul",
    ];
  }

  updateVenues = async () => {
    if (this.state.location !== "") {
      const venues = await autofillVenues(this.state.location);
      this.setState({ venues });
    }
  };

  updateGenres = async () => {
    if (this.state.genre !== "") {
      const genres = this.genres.filter((genre) =>
        genre.toLowerCase().includes(this.state.genre.toLowerCase())
      );
      this.setState({ genres });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "location") {
      this.updateVenues();
    }
    if (event.target.name === "genre") {
      console.log("updating genres");
      this.updateGenres();
    }
  };

  handleSuggestion = (event) => {
    console.log(event);
    event.preventDefault();
    if (event.target.className === "venue-suggestion") {
      this.setState({ location: event.target.innerHTML, venues: [] });
    } else if (event.target.className === "genre-suggestion") {
      this.setState({ genre: event.target.innerHTML, genres: [] });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.date === "" &&
      this.state.location === "" &&
      this.state.genre === ""
    ) {
      console.log("Please fill out a field");
    }

    const url = `/search/${
      new Date(this.state.date).getTime() / 1000 || "none"
    }/${this.state.location.toLowerCase() || "none"}/${
      this.state.genre.toLowerCase() || "none"
    }`;
    window.location.href = url;
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="search-bar">
        <div className="search-item search-item-bordered">
          <div className="search-bar-label">When</div>
          <DatePicker
            selected={this.state.date}
            onChange={(date) => this.setState({ date })}
            className="search-bar-input"
            placeholderText="Choose a Date"
            dateFormat="MMMM dd"
            shouldCloseOnSelect={true}
            calendarClassName="calendar"
            minDate={new Date()}
          />
        </div>
        <div className="search-item search-item-bordered">
          <div className="search-bar-label">Where</div>
          <input
            type="text"
            name="location"
            value={this.state.location}
            onChange={this.handleChange}
            className="search-bar-input"
            placeholder="Choose a Location"
            autoComplete="off"
          />
          {this.state.location !== "" && this.state.venues.length !== 0 && (
            <>
              <div className="venue-suggestions-arrow"></div>
              <div className="venue-suggestions">
                {this.state.venues.map((venue) => (
                  <button
                    className="venue-suggestion"
                    key={venue}
                    onClick={this.handleSuggestion}
                  >
                    {venue.name}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="search-item">
          <div className="search-bar-label">What</div>
          <input
            type="text"
            name="genre"
            value={this.state.genre}
            onChange={this.handleChange}
            className="search-bar-input"
            placeholder="Choose a genre"
            autoComplete="off"
          />
          {this.state.genre !== "" && this.state.genres.length !== 0 && (
            <>
              <div className="venue-suggestions-arrow"></div>
              <div className="venue-suggestions">
                {this.state.genres.map((genre) => (
                  <button
                    className="genre-suggestion"
                    key={genre}
                    onClick={this.handleSuggestion}
                  >
                    {genre}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
        <button className="search-button" type="submit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="30"
            viewBox="0 -960 960 960"
            width="30"
          >
            <path
              fill="#fff    "
              d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
            />
          </svg>
        </button>
      </form>
    );
  }
}

export default SearchBar;
