import React, { useState, useEffect } from "react";
import "../styles/convoThumbnail.css";
import { getUser } from "../helpers/firestore";

function ConvoThumbnail(props) {
  const [partner, setPartner] = useState({});

  useEffect(() => {
    const resolvePartner = async (uid) => {
      const partner = await getUser(uid);
      setPartner(partner);
    };
    resolvePartner(props.partner);
  }, [props.partner]);

  const handleClick = () => {
    // Handle click event here
    props.handler(props.bookingID);
    console.log("ConvoThumbnail clicked");
  };

  return (
    <div
      className={`convo-thumbnail ${
        props.selected === props.bookingID ? "selected" : ""
      }`}
      onClick={handleClick}
    >
      <img
        src={partner.image}
        alt={partner.name}
        className="convo-thumbnail-image"
      />
      <div className="convo-thumbnail-text">
        <div className="convo-header">
          <div className="convo-title">{partner.name}</div>
        </div>
        <div className="convo-thumbnail-secondary-text">
          <div className="convo-thumbnail-description">
            {props.message.content}
          </div>
          <div className="convo-thumbnail-date">
            {props.message.date.split(",")[0]}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConvoThumbnail;
