import React from "react";
import "../styles/savedThumbnail.css";
import { Link } from "react-router-dom";
import FilterTag from "./filterTag";

function SavedThumbnail(event) {
  event = event.event; //idk why this is necessary

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date.seconds * 1000).toLocaleDateString(undefined, options);
  }

  return (
    <Link to={`/event/${event.id}`} style={{ textDecoration: "none" }}>
      <div className="saved-thumbnail">
        <div className="saved-thumbnail-image">
          <img src={event.image} alt={event.performer} />
        </div>
        <div className="saved-thumbnail-details">
          <div className="saved-thumbnail-title">{event.performer}</div>
          <div className="saved-thumbnail-location">
            {event.location} on {formatDate(event.date)}
          </div>
          <div className="saved-thumbnail-desc">{event.description}</div>
          <div className="saved-thumbnail-tags">
            {event.tags.map((tag) => (
              <FilterTag tag={tag} key={tag} toggle={() => {}} />
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SavedThumbnail;
