import React from "react";
import Autosuggest from "react-autosuggest";
import theme from "../styles/bandRegistration.css";

const genres = [
  "Rock",
  "Jazz",
  "Pop",
  "Modern",
  "Country",
  "Folk",
  "Hip Hop",
  "Dance",
  "R&B",
  "Metal",
  "Indie",
  "Soul",
];

const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : genres.filter(
        (genre) => genre.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <div className="suggestion">{suggestion}</div>
);

class GenreAutocomplete extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.props.updateParentGenre(newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    this.setState({
      value: "",
    });
    this.props.updateParentGenres([
      ...this.props.parentgenres,
      suggestionValue,
    ]);
    this.props.updateParentGenre("");
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Genre",
      value: this.props.value,
      parentgenres: this.props.genres,
      onChange: (event, { newValue }) => {
        this.onChange(event, { newValue });
      },
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={theme}
      />
    );
  }
}

export default GenreAutocomplete;
