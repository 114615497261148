import React from "react";
import "../styles/footerBar.css";

const FooterBar = () => {
  return (
    <div className="footer-bar">
      <div className="footer-content">
        <p>&copy; 2023 Jammin Together LLC</p>
        <a href="/partners">Our Partners</a>
        <a href="/terms">Terms</a>
        <a href="/privacy">Privacy</a>
        <a href="mailto:customersupport@jammintogether.com">Contact</a>
      </div>
    </div>
  );
};

export default FooterBar;
