import React, { useEffect } from "react";
import "../styles/band.css";
import { useParams } from "react-router-dom";
import { getUser } from "../helpers/firestore";
import { ThreeDots } from "react-loader-spinner";
import FilterTag from "../components/filterTag";
import { getSelf, follow, unfollow } from "../helpers/firestore";
import { auth } from "../helpers/firebase";

function BandPage() {
  let { id } = useParams();
  const [band, setBand] = React.useState(null);
  const [tags, setTags] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [following, setFollowing] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      const data = await getUser(id);
      if (data) {
        setBand(data);
        setTags(data.tags);
      }
    };
    const loadUser = async () => {
      const userData = await getSelf();
      if (userData) {
        setUser(userData);
        if (userData.following.includes(id)) {
          setFollowing(true);
        }
      } else {
        setUser(null);
      }
    };
    loadData();
    loadUser();
  }, []);

  return (
    <div className="band-page">
      {band ? (
        <div className="band-wrapper">
          <a id="close" href="/">
            X
          </a>
          <div className="image-container">
            <img src={band.image} alt={band.name} className="band-image" />
            <div className="save-button">Save</div>
          </div>
          <div className="band-details">
            <div className="band-tags">
              {tags.map((tag) => {
                return <FilterTag tag={tag} />;
              })}
            </div>
            <div className="band-header">{band.name}</div>
            <div className="band-subheader">Operating near: {band.zip}</div>
            <div className="band-description">{band.description}</div>
            {user ? (
              following ? (
                <button
                  onClick={() => {
                    unfollow(auth.currentUser.uid, band.id);
                    setFollowing(false);
                  }}
                  id="follow"
                >
                  Unfollow -
                </button>
              ) : (
                <button
                  onClick={() => {
                    follow(auth.currentUser.uid, band.id);
                    setFollowing(true);
                  }}
                  id="follow"
                >
                  Follow +
                </button>
              )
            ) : (
              <button
                onClick={() => window.location.replace("/login")}
                id="follow"
              >
                Follow +
              </button>
            )}
          </div>
        </div>
      ) : (
        <ThreeDots color="#ed46c7" height={80} width={80} />
      )}
    </div>
  );
}

export default BandPage;
