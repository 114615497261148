import React, { useEffect } from "react";
import "../styles/event.css";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import FilterTag from "../components/filterTag";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import {
  follow,
  getSelf,
  unfollow,
  getEvent,
  saveEvent,
  unsaveEvent,
} from "../helpers/firestore";
import { auth } from "../helpers/firebase";
import { LuBookmark } from "react-icons/lu";

function EventPage() {
  let { id } = useParams();
  const [event, setEvent] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [following, setFollowing] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      const data = await getEvent(id);
      if (data) {
        setEvent(data);
        console.log(data.loc);
      }
    };
    const loadUser = async () => {
      const userData = await getSelf();
      if (userData) {
        setUser(userData);
        if (userData.following.includes(id)) {
          setFollowing(true);
        }
        if (userData.saved.includes(id)) {
          setSaved(true);
        }
      } else {
        setUser(null);
      }
    };
    loadData();
    loadUser();
  }, []);

  /* Function to get the ordinal suffix of a date */
  const nth = (d) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  /* Function to convert date to a readable format */
  const convertDate = (date) => {
    let formattedDate = new Date(date.seconds).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    formattedDate =
      formattedDate.split(" ").slice(0, 2).join(" ") +
      nth(formattedDate.split(" ")[1]) +
      " " +
      formattedDate.split(" ").slice(2).join(" ");
    return formattedDate;
  };

  return (
    <div className="event-page">
      {event ? (
        <div className="event-wrapper">
          <a id="close" href="/">
            X
          </a>
          <div className="image-container">
            <img
              src={event.image}
              alt={event.performer}
              className="event-image"
            />
            {user ? (
              saved ? (
                <button
                  onClick={() => {
                    unsaveEvent(id);
                    setSaved(false);
                  }}
                  id="save"
                >
                  <LuBookmark id="saved" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    saveEvent(id);
                    setSaved(true);
                  }}
                  id="save"
                >
                  <LuBookmark />
                </button>
              )
            ) : (
              <button
                onClick={() => window.location.replace("/login")}
                id="save"
              >
                <LuBookmark />
              </button>
            )}
          </div>
          <div className="event-details">
            <div className="event-date">{convertDate(event.date)}</div>
            <a className="event-location" href={`/venue/${event.venue}`}>
              {event.location}
            </a>
            <div className="event-tags">
              {event.tags.slice(0, 3).map((tag) => {
                return <FilterTag tag={tag} />;
              })}
            </div>
            <a className="event-title" href={`/band/${event.band}`}>
              {event.performer}
            </a>
            <button
              id="buy"
              onClick={() => {
                if (event.loc && event.loc._lat && event.loc._long) {
                  window.open(
                    `http://maps.google.com/maps?q=${event.loc._lat},${event.loc._long}&z=15`,
                    "_blank"
                  );
                } else {
                  alert("Directions not available");
                }
              }}
            >
              Get Directions
            </button>
            {user ? (
              following ? (
                <button
                  onClick={() => {
                    unfollow(auth.currentUser.uid, event.band);
                    setFollowing(false);
                  }}
                  id="follow"
                >
                  Unfollow -
                </button>
              ) : (
                <button
                  onClick={() => {
                    follow(auth.currentUser.uid, event.band);
                    setFollowing(true);
                  }}
                  id="follow"
                >
                  Follow +
                </button>
              )
            ) : (
              <button
                onClick={() => window.location.replace("/login")}
                id="follow"
              >
                Follow +
              </button>
            )}
            {event.loc && event.loc._lat && event.loc._long && (
              <div className="map-container">
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <Map
                    style={{ width: "100%", height: "100%" }}
                    defaultCenter={{
                      lat: event.loc._lat,
                      lng: event.loc._long,
                    }}
                    defaultZoom={15}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                  />
                </APIProvider>
              </div>
            )}
          </div>
        </div>
      ) : (
        <ThreeDots className="loader" color="#ed46c7" />
      )}
    </div>
  );
}

export default EventPage;
