import React from "react";
import "../styles/followingUserThumb.css";

function FollowingUser(props) {
  return (
    <div className="following-user-thumb">
      <div className="following-user-thumb-image">
        <img src={props.user.image} alt="user" />
      </div>
      <div className="following-user-name">{props.user.name}</div>
    </div>
  );
}

export default FollowingUser;
